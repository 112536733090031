<template>
  <div class="flex flex-col">
    <div class="divide-y divide-gray-950 bg-gray-900 rounded-lg max-h-[300px] overflow-y-auto">
      <table class="w-full divide-y divide-gray-950">
        <thead>
          <tr>
            <th class="text-text-primary font-medium text-left px-4 py-3">{{ title }}</th>
            <th class="text-text-secondary font-medium tracking-[1.5px] text-xs uppercase text-right px-4 py-3 w-1/4">Current Avg</th>
            <th class="text-text-secondary font-medium tracking-[1.5px] text-xs uppercase text-right px-4 py-3 w-1/4">Target Avg</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-950 w-full">
          <tr v-for="(stat, index) in stats" :key="index" class="p-4 w-full gap-2 items-center">
            <td class="font-medium text-text-secondary text-left p-4">{{ stat.label }}</td>
            <td class="font-medium text-right p-4 w-1/4" :class="getStatColor(stat?.stat?.deviations_away)">
              {{ formatDataString(stat?.stat?.players_value, stat?.stat?.unit_type) || 0 }}
            </td>
            <td class="text-right text-text-primary p-4 w-1/4">
              {{ formatDataString(stat?.stat?.rating_range_average_value, stat?.stat?.unit_type) || 0 }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
import { useStore } from 'vuex';
  import {
  formatStatString,
  toTitleCase,
  formatDataString,
  formatData
} from "../../utils/formatters";
import { Chart, registerables } from "chart.js";
import { LineChart } from "vue-chart-3";
import { createClickablePointsPlugin } from "../../utils/chartPlugins.js";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  stats: {
    type: Array,
    required: true,
  },
});


function getStatColor(deviation) {
  switch (true) {
    case deviation >= 0.5:
      return 'text-success-400';
    case deviation >= 0:
      return 'text-text-primary';
    case deviation >= -.25:
      return 'text-text-primary';
    case deviation >= -0.5:
      return 'text-yellow-400';
    case deviation >= -1:
      return 'text-warning-400';
    default:
      return 'text-danger-400';
  }
}
</script>

<style scoped>
/* Add any specific styles for StatSection here */
::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #1E2733;
  opacity: 0;
}

::-webkit-scrollbar
{
	width: 12px;
  opacity: 0;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(67, 67, 67, 0.3);
	background-color: #37485D;
}
</style>