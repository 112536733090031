<template>
  <div
    class="flex flex-col gap-4 w-full"
  >
    <div class="flex items-center gap-2">
      <h1 class="text-text-primary text-3xl font-semibold">Restrat</h1>
      <div class="has-tooltip">
        <img src="../../assets/icons/info-icon.svg" alt="Avatar" class="w-6 h-6 cursor-pointer" />
        <div class="tooltip bg-gray-900 rounded-lg p-2 ml-8 -mt-7">
          <p class="text-text-secondary text-xs">Restrat is a tool that allows you to replay a match from a demo and relive the moments from your match!</p>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-4 w-full">
      <div class="flex gap-2 items-center">
        <div class="flex flex-col gap-2 flex-1">
        <h1 class="text-text-primary text-lg font-semibold">Rounds</h1>
        <p class="text-text-secondary text-sm">Select the round and click "Start demo" to replay the round on the server</p>
        </div>
        <div class="flex gap-2">
          <button @click="startDemo(currentRound)" class="bg-primary-600 hover:bg-primary-500 duration-300 text-text-primary px-3 py-2 rounded-lg text-sm flex gap-2 items-center">
            <div v-if="loadingRound" class="flex justify-center items-center">
              <svg
                class="animate-spin h-full font-normal w-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
            <HandThumbUpIcon v-else-if="loadingRoundSuccess" class="text-green-500 w-4 h-4"></HandThumbUpIcon>
            <img v-else src="../../assets/icons/play.svg" alt="Play" class="w-4 h-4 brightness-200" />
            <h1 v-if="activeServer?.status === 'online'">Load round</h1>
            <h1 v-else>Start demo</h1>
          </button>
        </div>
      </div>


      <div class="w-full flex-col gap-6 flex-1">
        <div class="flex overflow-x-auto pb-2 flex-1 w-full gap-px">

          <div class="flex flex-col divide-y divide-gray-950 text-text-secondary rounded-l-lg overflow-hidden shrink-0">
            <h1 class="text-text-secondary text-sm p-3 bg-gray-900 w-[144px] truncate">{{ matchData?.parsed_data?.team_info[0]?.team_name || 'Team 1' }}</h1>
            <h1 class="text-text-secondary text-sm p-3 bg-gray-900 w-[144px] truncate">{{ matchData?.parsed_data?.team_info[1]?.team_name || 'Team 2' }}</h1>
          </div>

          <div class="flex divide-x divide-gray-950 bg-gray-900 min-w-max shrink-0 overflow-hidden flex-1">
            
            <div class="flex divide-x divide-gray-950 min-w-max flex-1">
              <div v-for="(round, index) in firstHalfRounds" :key="index" 
                @click="updateRound(index + 1)"
                class="flex flex-col p-3 text-text-secondary relative items-center justify-center w-12 shrink-0 cursor-pointer flex-1"
                :class="[currentRound === index + 1 ? 'bg-gradient-to-b from-gray-900 to-gray-800' : '']">
                <h1 class="text-text-secondary text-sm whitespace-nowrap">{{ index + 1 }}</h1>
                <img 
                  :src="`/img/icons/${getRoundEndReasonString(round.winning_reason)}.svg`" 
                  alt="Team 1" 
                  class="w-4 h-4 absolute"
                  :class="[
                    round.winning_team === 3 
                      ? (isTeamOnCT(index + 1, 0) ? 'top-3' : 'bottom-3') 
                      : (isTeamOnCT(index + 1, 0) ? 'bottom-3' : 'top-3')
                  ]"
                  style="left: 50%; transform: translateX(-50%)"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-2 items-center justify-center px-3 shrink-0">
            <h1 class="text-sm text-[#687cca]">{{ halftimeScore.team3 }}</h1>
            <img src="/img/icons/switch.svg" alt="Switch" class="w-5 h-5" />
            <h1 class="text-sm text-[#e38617]">{{ halftimeScore.team2 }}</h1>
          </div>

          <div class="flex divide-x divide-gray-950 bg-gray-900 min-w-max shrink-0 overflow-hidden flex-1"
          :class="[currentRound > 24 ? 'rounded-r-lg' : '']">
            <div class="flex divide-x divide-gray-950 min-w-max flex-1">
              <div v-for="(round, index) in secondHalfRounds" :key="index" 
                @click="updateRound(index + 13)"
                class="flex flex-col p-3 text-text-secondary relative items-center justify-center w-12 shrink-0 cursor-pointer flex-1"
                :class="[currentRound === index + 13 ? 'bg-gradient-to-b from-gray-900 to-gray-800' : '']">
                <h1 class="text-text-secondary text-sm whitespace-nowrap">{{ index + 13 }}</h1>
                <img 
                  :src="`/img/icons/${getRoundEndReasonString(round.winning_reason)}.svg`" 
                  alt="Team 1" 
                  class="w-4 h-4 absolute"
                  :class="[
                    round.winning_team === 3 
                      ? (isTeamOnCT(index + 13, 0) ? 'top-3' : 'bottom-3') 
                      : (isTeamOnCT(index + 13, 0) ? 'bottom-3' : 'top-3')
                  ]"
                  style="left: 50%; transform: translateX(-50%)"
                />
              </div>
            </div>
          </div>

          <div v-if="overtimeRounds" class="flex flex-col gap-2 items-center justify-center px-3 shrink-0">
            <h1 class="text-sm text-[#e38617]">{{ regulationScore.team2 }}</h1>
            <img src="/img/icons/switch.svg" alt="Switch" class="w-5 h-5" />
            <h1 class="text-sm text-[#687cca]">{{ regulationScore.team3 }}</h1>
          </div>

          <div v-if="overtimeRounds" class="flex" v-for="(groupedRounds, group_index) in overtimeRounds" :key="group_index">
            <div class="flex divide-x divide-gray-950 bg-gray-900 min-w-max shrink-0 overflow-hidden flex-1"
            :class="[group_index % 2 === 1 && group_index === overtimeRounds.length - 1 ? 'rounded-r-lg' : '']">
              <div class="flex divide-x divide-gray-950 min-w-max flex-1">
                <div v-for="(round, index) in groupedRounds.rounds" :key="index" 
                  @click="updateRound(index + groupedRounds.startingRound)"
                  class="flex flex-col p-3 text-text-secondary relative items-center justify-center w-12 shrink-0 cursor-pointer flex-1"
                  :class="[currentRound === index + groupedRounds.startingRound ? 'bg-gradient-to-b from-gray-900 to-gray-800' : '']">
                  <h1 class="text-text-secondary text-sm whitespace-nowrap">{{ index + groupedRounds.startingRound }}</h1>
                  <img 
                    :src="`/img/icons/${getRoundEndReasonString(round.winning_reason)}.svg`" 
                    alt="Team 1" 
                    class="w-4 h-4 absolute"
                    :class="[
                      round.winning_team === 3 
                        ? (isTeamOnCT(index + groupedRounds.startingRound, 0) ? 'top-3' : 'bottom-3') 
                        : (isTeamOnCT(index + groupedRounds.startingRound, 0) ? 'bottom-3' : 'top-3')
                    ]"
                    style="left: 50%; transform: translateX(-50%)"
                  />
                </div>
              </div>
            </div>
            <div v-if="overtimeRounds && group_index < overtimeRounds.length - 1" class="flex px-3 flex-col gap-2 items-center justify-center px-2 shrink-0">
              <h1 class="text-sm" :class="[group_index % 2 === 0 ? 'text-[#e38617]' : 'text-[#687cca]']">
                {{ getOvertimeHalfTimeScore(group_index)[group_index % 2 === 0 ? 'team3' : 'team2'] }}
              </h1>
              <img src="/img/icons/switch.svg" alt="Switch" class="w-5 h-5" />
              <h1 class="text-sm" :class="[group_index % 2 === 0 ? 'text-[#687cca]' : 'text-[#e38617]']">
                {{ getOvertimeHalfTimeScore(group_index)[group_index % 2 === 0 ? 'team2' : 'team3'] }}
              </h1>
            </div>
          </div>

        </div>
      </div>

      <div v-if="roundLoading" class="flex justify-center items-center h-full">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="animate-spin"
        >
          <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
          <path
            d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
            stroke="#4E54C8"
            stroke-width="2"
          />
        </svg>
      </div>
      <div v-else class="gap-6 flex-col flex sm:flex-row">
        <div class="flex flex-col divide-y divide-gray-950 bg-gray-900 rounded-lg w-full">
          <div class="flex gap-3 p-3 items-center w-full">
            <img :src="`/img/icons/${isTeamOnCT(currentRound, 0) ? 'ct' : 't'}-badge.png`" alt="Team 1" class="w-6 h-6" />
            <h1 class="text-text-primary text-sm flex-1">{{ matchData?.parsed_data?.team_info[0]?.team_name || 'Team 1' }}</h1>
            <h1 class="text-text-primary text-sm">{{ roundData?.cTScore }}</h1>
          </div>
          <div class="flex flex-col gap-1 p-3" v-for="(player, index) in team1Players" :key="index">
            <div class="flex gap-2 items-center">
              <h1 class="text-text-primary text-sm">{{ player.playerName }}</h1>
            </div>
            <div class="flex gap-2 items-center">
              <img
                v-if="player.hasHelmet"
                class="h-4 brightness-75"
                src="../../assets/icons/headarmor.svg"
              />
              <img
                v-else-if="player.armor > 0"
                class="h-4 brightness-75"
                src="../../assets/icons/armor.svg"
              />
              <img
                v-if="player.hasDefuse"
                class="h-3 brightness-75"
                src="../../assets/icons/defuser.svg"
              />
              <h1 class="text-text-secondary text-xs">${{ player.cash }}</h1>
              <h1 class="text-danger-500 text-xs flex-1">-${{ player.cashSpentThisRound }}</h1>

              <div v-for="item in getSortedInventory(player.inventory)">
                <template v-if="item.weaponClass === 'Grenade' && getPrimary(player).weaponName !== item.weaponName">
                  <template v-if="item.weaponName === 'Flashbang' && player.flashbangAmmo === 2">
                    <div class="flex gap-0.5">
                      <img
                        class="h-4 brightness-75"
                        :src="'/img/icons/' + item.weaponName + '.png'"
                      />
                      <img
                        class="h-4 brightness-75"
                        :src="'/img/icons/' + item.weaponName + '.png'"
                      />
                    </div>
                  </template>
                  <img
                    v-else
                    class="h-4 brightness-75"
                    :src="'/img/icons/' + item.weaponName + '.png'"
                  />
                </template>
              </div>

              <img
                v-if="getPrimary(player)"
                class="h-4"
                :src="'/img/icons/' + getPrimary(player).weaponName + '.png'"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-col divide-y divide-gray-950 bg-gray-900 rounded-lg w-full">
          <div class="flex gap-3 p-3 items-center w-full">
            <img :src="`/img/icons/${isTeamOnCT(currentRound, 1) ? 'ct' : 't'}-badge.png`" alt="Team 1" class="w-6 h-6" />
            <h1 class="text-text-primary text-sm flex-1">{{ matchData?.parsed_data?.team_info[1]?.team_name || 'Team 2' }}</h1>
            <h1 class="text-text-primary text-sm">{{ roundData?.tScore }}</h1>
          </div>
          <div class="flex flex-col gap-1 p-3" v-for="(player, index) in team2Players" :key="index">
            <div class="flex gap-2 items-center">
              <h1 class="text-text-primary text-sm">{{ player.playerName }}</h1>
            </div>
            <div class="flex gap-2 items-center">
              <img
                v-if="player.hasHelmet"
                class="h-4 brightness-75"
                src="../../assets/icons/headarmor.svg"
              />
              <img
                v-else-if="player.armor > 0"
                class="h-4 brightness-75"
                src="../../assets/icons/armor.svg"
              />
              <img
                v-if="player.hasDefuse"
                class="h-3 brightness-75"
                src="../../assets/icons/defuser.svg"
              />
              <h1 class="text-text-secondary text-xs">${{ player.cash }}</h1>
              <h1 class="text-danger-500 text-xs flex-1">-${{ player.cashSpentThisRound }}</h1>

              <div v-for="item in getSortedInventory(player.inventory)">
                <template v-if="item.weaponClass === 'Grenade' && getPrimary(player).weaponName !== item.weaponName">
                  <template v-if="item.weaponName === 'Flashbang' && player.flashbangAmmo === 2">
                    <div class="flex gap-0.5">
                      <img
                        class="h-4 brightness-75"
                        :src="'/img/icons/' + item.weaponName + '.png'"
                      />
                      <img
                        class="h-4 brightness-75"
                        :src="'/img/icons/' + item.weaponName + '.png'"
                      />
                    </div>
                  </template>
                  <img
                    v-else
                    class="h-4 brightness-75"
                    :src="'/img/icons/' + item.weaponName + '.png'"
                  />
                </template>
              </div>

              <img
                v-if="getPrimary(player)"
                class="h-4"
                :src="'/img/icons/' + getPrimary(player).weaponName + '.png'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, defineEmits } from 'vue';
import { useStore } from "vuex";
import axios from "axios";
import protobuf from "protobufjs";
import { HandThumbUpIcon } from '@heroicons/vue/20/solid';
import { decompressGzipFromUrl } from '../../utils/compression_util';

const store = useStore();
const emit = defineEmits(['start-demo']);
const currentRound = ref(1);
const roundData = ref(null);
const activeServer = computed(() => store.getters.activeServer);
const roundLoading = ref(false);
const props = defineProps({
  matchData: {
    type: Object,
    required: true,
  },
  startDemo: {
    type: Function,
    required: true,
  },
  loadingRound: {
    type: Boolean,
    required: true,
  },
  loadingRoundSuccess: {
    type: Boolean,
    required: true,
  },
});

function startDemo() {
  emit('start-demo', currentRound.value);
}

async function loadRoundData() {
  roundLoading.value = true;
  await axios({
    method: "get",
    url: `/demos/${props.matchData.id}/round/${currentRound.value}`,
    headers: {
      "X-TEAM-ID": store.state.activeTeam.id,
    },
  })
    .then(({ data }) => {
      if(props.matchData.status !== 'ready') return;
      decodeFromURL(data.url);
    })
    .catch((error) => {
      console.log(error);
    });
}

async function decodeFromURL(url) {
  try {
    // Fetch the zip file using Axios
    if (import.meta.env.VITE_ENVIRONMENT !== 'local.') {
      const response = await axios.get(url, {
        responseType: "arraybuffer",
      });
      const protoSchemaURL = new URL("/demo.proto", window.location.href).href;
      const {data: protoSchema} = await axios.get(protoSchemaURL, {
        responseType: "text",
      });

      let buffer;
      try {
        buffer = await decompressGzipFromUrl(url);
      } catch (error) {
        roundLoading.value = false;
        buffer = response.data;
      }
      const root = protobuf.parse(protoSchema).root;
      const roundDataBuffer = root.lookupType("Round");
      roundData.value = roundDataBuffer.decode(new Uint8Array(buffer));
      roundLoading.value = false;
      console.log(roundData.value);
    } else {
      const buffer = await decompressGzipFromUrl('/round-7.buf');
      const protoSchemaURL = new URL("/demo.proto", window.location.href).href;
      const {data: protoSchema} = await axios.get(protoSchemaURL, {
        responseType: "text",
      });
      const root = protobuf.parse(protoSchema).root;
      const roundDataBuffer = root.lookupType("Round");
      roundData.value = roundDataBuffer.decode(new Uint8Array(buffer));
    }
    
  } catch (error) {
    roundLoading.value = false;
    console.error("Error loading files:", error);
  }
}

const firstHalfRounds = computed(() => {
  return props.matchData.parsed_data?.round_info.slice(0, 12);
});

const secondHalfRounds = computed(() => {
  return props.matchData.parsed_data?.round_info.slice(12, 24);
});

const overtimeRounds = computed(() => {
  const totalRounds = props.matchData.parsed_data?.round_info.length || 0;

  if (totalRounds <= 24) {
    return null; // No overtime
  }

  // Get all rounds after round 24
  const allOvertimeRounds = props.matchData.parsed_data.round_info.slice(24);
  
  // Split overtime rounds into groups of three
  const groupedRounds = [];
  for (let i = 0; i < allOvertimeRounds.length; i += 3) {
    const threeRounds = allOvertimeRounds.slice(i, i + 3);
    if (threeRounds.length === 3) { // Only add complete sets of three
      groupedRounds.push({
        rounds: threeRounds,
        team1Wins: threeRounds.filter(round => round.winning_team === (i % 2 === 0 ? 2 : 3)).length,
        team2Wins: threeRounds.filter(round => round.winning_team === (i % 2 === 0 ? 3 : 2)).length,
        startingRound: i + 25 // Round number starts at 25 for overtime
      });
    }
  }
console.log(groupedRounds)
  return groupedRounds;
});

const regulationScore = computed(() => {
  return {
    team2: 12,
    team3: 12
  }
})

function isTeamOnCT(round, team_index) {
  const totalRounds = props.matchData.parsed_data?.round_info.length || 0;

  // determine how many full overtimes exist
  const totalOvertimeRounds = totalRounds > 24 ? totalRounds - 24 : 0;
  const totalOTPeriods = Math.floor(totalOvertimeRounds / 6);

  // find where the last half starts
  const lastHalfStartRound = totalRounds - 3; // last half is always the final 3 rounds

  // team 0 is always T in the last half
  let isTeam0OnCT = false;

  // walk back through all OT periods to determine starting side
  for (let i = 0; i < totalOTPeriods; i++) {
    isTeam0OnCT = !isTeam0OnCT; // swap sides at each OT half
  }

  // for regulation matches, adjust initial sides
  if (totalRounds <= 24) {
    // regulation game → first half team 0 starts as CT
    isTeam0OnCT = true;
  }

  // determine sides based on round number
  if (round >= lastHalfStartRound) {
    return team_index === (isTeam0OnCT ? 0 : 1); // last half, team 0 is T
  }

  if (round <= 12) {
    return team_index === (isTeam0OnCT ? 1 : 0); // first half
  } else if (round <= 24) {
    return team_index === (isTeam0OnCT ? 0 : 1); // second half
  }

  // handle overtime
  const overtimeRound = round - 24;
  const otPeriod = Math.floor((overtimeRound - 1) / 6);
  const isSecondHalf = (overtimeRound - 1) % 6 >= 3;

  let isOTTeam0OnCT = isTeam0OnCT;
  for (let i = 0; i < otPeriod; i++) {
    isOTTeam0OnCT = !isOTTeam0OnCT;
  }

  if (isSecondHalf) {
    isOTTeam0OnCT = !isOTTeam0OnCT;
  }

  return team_index === (isOTTeam0OnCT ? 0 : 1);
}

function getOvertimeHalfTimeScore(index) {
  let team2Score = 0;
  let team3Score = 0;

  // Get all overtime rounds up to current index
  const overtimeRoundsToIndex = overtimeRounds.value?.slice(0, index + 1) || [];

  // Sum up scores from each overtime period
  overtimeRoundsToIndex.forEach(period => {
    team2Score += period.team2Wins;
    team3Score += period.team1Wins;
  });

  return {
    team2: team2Score + regulationScore.value.team2,
    team3: team3Score + regulationScore.value.team3
  };
}

const halftimeScore = computed(() => {
  let team2Score = 0;
  let team3Score = 0;
  
  // Get first 12 rounds
  const firstHalf = props.matchData.parsed_data?.round_info?.slice(0, 12) || [];
  
  // Count wins for each team
  firstHalf.forEach(round => {
    if (round.winning_team === 2) {
      team2Score++;
    } else if (round.winning_team === 3) {
      team3Score++; 
    }
  });

  return {
    team2: team2Score,
    team3: team3Score
  };
});

const switchTeams = computed(() => {
  return overtimeRounds.value?.length % 2 === 0;
})

const team1Players = computed(() => {
  if (!roundData.value?.frames[0]) return [];
  
  // If team0 is CT, get CT players, otherwise get T players
  return (isTeamOnCT(currentRound.value, 0) ? 
    roundData.value.frames[0].ct.players : 
    roundData.value.frames[0].t.players)
    ?.filter(player => player.hp > 0)
    ?.sort((a, b) => {
      if (a.playerName?.toLowerCase() < b.playerName?.toLowerCase()) return -1;
      if (a.playerName?.toLowerCase() > b.playerName?.toLowerCase()) return 1;
      return 0;
    });
});

const team2Players = computed(() => {
  if (!roundData.value?.frames[0]) return [];
  
  // If team1 is CT, get CT players, otherwise get T players
  return (isTeamOnCT(currentRound.value, 1) ? 
    roundData.value.frames[0].ct.players : 
    roundData.value.frames[0].t.players)
    ?.filter(player => player.hp > 0)
    ?.sort((a, b) => {
      if (a.playerName?.toLowerCase() < b.playerName?.toLowerCase()) return -1;
      if (a.playerName?.toLowerCase() > b.playerName?.toLowerCase()) return 1;
      return 0;
    });
});

function updateRound(round) {
  currentRound.value = round;
  loadRoundData();
}

function getPrimary(player) {
  let primary;
  player.inventory.forEach((item) => {
    if (item.weaponClass === "Rifle") {
      primary = item;
    } else if (
      item.weaponClass !== "Pistols" &&
      item.weaponClass !== "Grenade" &&
      item.weaponClass !== "Equipment"
    ) {
      primary = item;
    } else if (item.weaponClass === "Pistols" && !primary) {
      primary = item;
    }
  })

  if(!primary) return player.inventory[0]

  return primary;
}

function getSortedInventory(inventory) {
  const grenadeOrder = ["C4", "Decoy Grenade", "Flashbang", "HE Grenade", "Molotov", "Incendiary Grenade", "Smoke Grenade"];

  return inventory.sort((a, b) => grenadeOrder.indexOf(a.weaponName) - grenadeOrder.indexOf(b.weaponName));
}

function getRoundEndReasonString(reason) {
  switch (reason) {
    case 1:
      return 'texplode';
    case 7:
      return 'ctdefuse';
    case 8:
      return 'ctskull';
    case 9:
      return 'tskull';
    case 12:
      return 'cttime';
    default:
      return '';
  }
}

onMounted(() => {
  loadRoundData();
});

</script>

<style scoped>

::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #1E2733;
  opacity: 0;
}

::-webkit-scrollbar
{
	width: 12px;
  opacity: 0;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(67, 67, 67, 0.3);
	background-color: #37485D;
}
</style>