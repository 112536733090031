<template>
  <div class="flex flex-col gap-4">  
    <div
      v-if="lowestStats && lowestStats.length > 0"
      class="grid grid-cols-12 gap-6 items-center items-stretch"
    >
      <div class="flex flex-col gap-3 flex-1 col-span-12 lg:col-span-4">
        <div
          v-for="stat in lowestStats.slice(0, 4)"
          class="flex p-4 rounded-lg bg-gray-900 items-center cursor-pointer hover:bg-gray-800 duration-300"
          :class="[
            selectedWeaknessStat?.title === stat.title
              ? 'border border-primary-500'
              : ' border border-gray-900',
          ]"
          @click="updateSelectedWeaknessStat(stat)"
        >
          <h2 class="text-text-secondary flex-1">{{ toTitleCase(formatStatString(stat?.title)) }}</h2>
          <h1 class="text-text-primary text-lg font-medium">
            {{ formatDataString(stat.value, stat.unit_type) }}
          </h1>
        </div>
      </div>

      <div v-if="selectedWeaknessStat" class="flex flex-col lg:flex-row col-span-12 lg:col-span-8 rounded-lg bg-gray-900">
        <div class="flex flex-1 flex-col gap-4">
          <div class="flex gap-4 pt-6 px-6 items-center">
            <div class="flex flex-col gap-1 flex-1">
              <h2 class="text-text-secondary text-sm">
                {{ toTitleCase(formatStatString(selectedWeaknessStat?.title)) }}
              </h2>
              <h1 class="text-text-primary text-lg font-medium">
                {{
                  formatDataString(
                    selectedWeaknessStat.value,
                    selectedWeaknessStat.unit_type
                  )
                }}
              </h1>
            </div>
            <div class="flex gap-1">
              <h1 class="font-medium text-danger-400 flex">
                <span v-if="!selectedWeaknessStat.is_ascending > 0">+</span>
                <span v-else>-</span>
                <h1>
                  {{
                    formatDataString(
                      Math.abs(
                        parseFloat(
                          selectedWeaknessStat.difference
                        )
                      ),
                      selectedWeaknessStat.unit_type
                    )
                  }}
                </h1>
              </h1>
            </div>
          </div>

          <div class="w-full px-6">
            <div class="w-full h-px bg-gray-800" />
          </div>

          <div class="text-sm text-text-secondary px-6 pb-2 flex-1">
            Your
            {{ toTitleCase(formatStatString(selectedWeaknessStat.title)) }} in this match was
            <span class="text-danger-400"
              >{{
                Math.abs(
                  parseFloat(
                    selectedWeaknessStat.percentDifference
                  )
                )
              }}% worse</span
            >
            than your rank's average.
          </div>

          <button
            @click="launchMod(selectedWeaknessStat.recommended_mods[0]?.modifier?.modifier_name)"
            class="py-2.5 mx-6 mb-6 rounded-md bg-primary-600 text-text-primary text-xs z-50 cursor-pointer hover:bg-primary-500 duration-300"
          >
            Improve Now
          </button>
        </div>

        <div class="flex-1 flex items-stretch p-6">
          <div
            class="flex-1 bg-gray-800 rounded-lg border-gray-700 border p-5 flex flex-col"
          >
            <div class="flex-1 flex gap-8">
              <div class="flex flex-col gap-1">
                <div class="flex gap-1 items-center">
                  <div class="h-2 w-2 rounded-sm bg-primary-500" />
                  <h2 class="text-text-secondary text-xs">
                    Your Avg.
                  </h2>
                </div>
                <h1 class="text-text-primary text-lg font-medium">
                  {{
                    formatDataString(
                      selectedWeaknessStat.value,
                      selectedWeaknessStat.unit_type
                    )
                  }}
                </h1>
              </div>
              <div class="flex flex-col gap-1">
                <div class="flex gap-1 items-center">
                  <div class="h-2 w-2 rounded-sm bg-secondary-500" />
                  <h2 class="text-text-secondary text-xs">Target Avg.</h2>
                </div>
                <h1 class="text-text-primary text-lg font-medium">
                  {{
                    formatDataString(
                      selectedWeaknessStat.rating_range_average_value,
                      selectedWeaknessStat.unit_type
                    )
                  }}
                </h1>
              </div>
            </div>
            <LineChart
              v-if="userMatchHistory"
              :options="options"
              :chartData="getChartData()"
              class="w-full max-h-[112px]"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="orderedStats && lowestStats.length === 0"
      class="flex col-span-8 rounded-lg bg-gray-900"
    >
      <div class="flex flex-col gap-4 p-6">
        <h1 class="text-text-primary text-lg font-medium">No weaknesses found!</h1>
        <p class="text-text-secondary text-sm">
          You performed well in this match!
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
  import {
  formatStatString,
  toTitleCase,
  formatDataString,
} from "../../utils/formatters";
import { Chart, registerables } from "chart.js";
import { LineChart } from "vue-chart-3";
import { createClickablePointsPlugin } from "../../utils/chartPlugins.js";

const props = defineProps({
  lowestStats: Array,
  selectedWeaknessStat: Object,
  updateSelectedWeaknessStat: Function,
  user: Object,
  userMatchHistory: Array,
  toTitleCase: Function,
  formatStatString: Function,
  formatDataString: Function,
  options: Object,
  getChartData: Function,
  launchMod: Function,
});

const store = useStore();
</script>

<style scoped>
/* Add any specific styles for the weaknesses section here */
</style>