<template>
    <div class="w-full h-full bg-gray-950 overflow-x-hidden">
      <Dashboard v-if="user" title="Match Page" no-padding="true">
        <div v-if="!demoData" class="h-full flex justify-center items-center w-full">
          <p v-if="loading" class="text-white">
            <svg
              class="animate-spin h-full font-normal w-12"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </p>
          <p v-else class="text-white">
            <div class="max-w-lg text-white">
            <h1 class="text-3xl font-bold text-center text-gray-200 mb-8">
              404 - Match Not Found
            </h1>
            <p class="text-gray-300 mb-6">
              Oops! It seems you're on the wrong bombsite.
            </p>
            <ul class="text-gray-300 mb-6">
              <li class="mb-2">
                1. <strong>Check your bomb site:</strong> Make sure you planted the right
                URL. Sometimes, a tiny typo can lead to big detours.
              </li>
              <li class="mb-2">
                2. <strong>Reach out to our team:</strong> If you believe this is a glitch
                on our end, our CTs are on high alert to defuse the situation. Just drop us
                a message at
                <a href="mailto:stefan@refrag.gg" class="text-blue-500">stefan@refrag.gg</a
                >, and we'll have our digital detectives investigate right away.
              </li>
              <li>
                3. <strong>Meanwhile, take a breather:</strong> Consider this a technical
                pause. Use this moment to strategize, or better yet, grab a snack and
                recharge for your next round on Refrag.gg.
              </li>
            </ul>
            <p class="text-gray-300 text-lg">
              Remember, even the best of us end up on the wrong bombsite sometimes. With a
              little patience and perseverance, you'll be back to fragging and clutching in
              no time!
            </p>
          </div>
          </p>
        </div>
        <div class="w-full h-full flex flex-col text-center items-center justify-center gap-4" v-else-if="demoData?.status !== 'ready' && demoData?.status !== 'failed'">
          <!-- loading spin wheel of height and width of 16 -->
          <svg
            class="animate-spin h-16 w-16 text-primary-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2
              5.291A7.962 7.962 0 014 12H0c0 3.042 1.135
              5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <h1 class="text-text-primary text-bold text-xl">Demo processing...</h1>
          <h2 class="text-text-secondary w-72">
            Your demo is currently being processed, this could take up to 5 minutes. Go shoot some heads, we will update this page when it's ready.
          </h2>
        </div>
        <div class="w-full h-full flex flex-col text-center items-center justify-center gap-4" v-else-if="demoData?.status === 'failed'">
          <div class="w-16 h-16 rounded-full bg-danger-900 flex justify-center items-center">
            <img class="h-10" src="../assets/icons/error.svg">
          </div>
          <h1 class="text-text-primary text-bold text-xl">Demo loading failed</h1>
          <h2 class="text-text-secondary w-72">
            Your demo has failed to process, create a support ticket on our <a href="https://discord.gg/Refrag" target="_blank" class="cursor-pointer underline text-text-primary" >discord</a> so we get this issue resolved. Make sure to include this demo ID in your ticket: <span class="text-danger-500">{{ route.params.id }}</span>
          </h2>
          <router-link to="/demos" class="px-4 py-3 bg-gray-800 rounded-md flex gap-2 items-center text-text-primary">
            <img class="h-4 w-4 brightness-200" src="../assets/icons/leftarrow.svg" />
            Go back
          </router-link>
        </div>
        <div v-else class="flex flex-col w-full">
          <div class="flex flex-col w-full">
            <div class="w-full relative h-[150px]lg:h-[250px]">
              <div class="absolute left-0 top-0 z-10 h-full w-full map-linear-gradient"></div>
              <img
                class="absolute left-0 top-0 h-full w-full object-cover object-center select-none"
                :src="`/maps/${demoData?.cs_map?.name}_cs2.png`"
              />
              <div class="w-full h-full px-4 lg:px-9 pt-6 flex flex-col">
                <div class="w-full grid grid-cols-3 gap-4 items-start">
                  <div class="flex gap-2 items-center">
                    <img
                      class="w-3 lg:w-5 h-3 lg:h-5 z-50 select-none"
                      :src="`/maps/map_icon_${demoData?.cs_map?.name}.svg`"
                    />
                    <span class="text-text-secondary z-50 text-xs lg:text-base">{{ demoData?.cs_map?.friendly_name }}</span>
                  </div>
  
                  <div
                    class="flex flex-col gap-2 items-center justify-center py-6"
                  >
                    <div class="z-50 text-text-secondary flex gap-2 items-center">
                      <h1>{{ demoData?.parsed_data?.team_info[0]?.team_name || 'Team 1' }}</h1>
                      vs
                      <h1>{{ demoData?.parsed_data?.team_info[1]?.team_name || 'Team 2' }}</h1>
                    </div>
                    <div
                      class="text-[48px] z-50 font-bold text-text-primary flex gap-2 items-center"
                    >
                      <h1>{{ demoData?.parsed_data?.team_info[0]?.score }}</h1>
                      -
                      <h1>{{ demoData?.parsed_data?.team_info[1]?.score }}</h1>
                    </div>
  
                    <div class="text-text-secondary text-sm z-50">
                      {{
                        moment(demoData?.played_on || demoData?.date).format(
                          "h:mm A, MMM Do, YYYY"
                        )
                      }}
                    </div>
                  </div>
  
                  <div class="flex gap-2 items-center justify-end">
                    <button
                      v-if="!demoData?.pov_locked"
                      class="py-2.5 px-3 bg-gray-800 text-text-primary text-xs z-50 cursor-pointer hover:bg-gray-700 duration-300 rounded-lg"
                      @click="goTo2DViewer()"
                    >
                      2D Viewer
                    </button>
                  </div>

                </div>
  
                <div class="w-full">
                  <nav
                    class="w-full flex flex-row relative gap-9 border-b border-gray-800 z-50"
                  >
                    <div
                      ref="restratDiv"
                      class="pb-4 text-center cursor-pointer text-text-primary flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start z-50"
                    >
                      <img
                        src="../assets/icons/restrat.svg"
                        class="h-5 w-5 duration-300 brightness-200"
                        alt="Stats icon"
                      />
                      <h3
                        class="leading-5 duration-300 text-text-primary"
                      >
                        Restrat
                      </h3>
                    </div>
                    <div class="absolute flex-row md:flex bottom-0 z-50">
                      <div
                        class="h-[2px] grow-transition"
                        :style="{ width: sectionUnderlinePadding + 'px' }"
                      />
                      <div
                        ref="sectionUnderline"
                        class="h-[2px] bg-primary-600 grow-transition"
                        :style="{ width: sectionUnderlineWidth + 'px' }"
                      />
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div class="px-4 lg:px-9 py-6">
              <MatchRestrat
                :match-data="demoData"
                :loading-round="loadingRound"
                :loading-round-success="loadingRoundSuccess"
                @start-demo="startDemo"
              />
            </div>
          </div>
        </div>
        <StartServerModal
          :server="serverInfo"
          :show="showServerModal"
          :showMapSelector="showMapSelector"
          @close="showServerModal = false"
        />
      </Dashboard>
    </div>
  </template>
  
  <script setup>
  import axios from "axios";
  import Dashboard from "../components/layouts/DashboardLayout.vue";
  import MatchRestrat from "../components/Matches/MatchRestrat.vue";
  import { computed, ref, getCurrentInstance } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { useAuth } from "@websanova/vue-auth/src/v3.js";
  import moment from "moment";
  import { useHead } from "@unhead/vue";
  import { onMounted } from "vue";
  import StartServerModal from "../components/StartServerModal.vue";
  
  const activeTab = ref("overview");
  const store = useStore();
  const restratDiv = ref();
  const sectionUnderlineWidth = ref(79);
  const sectionUnderlinePadding = ref(0);
  const route = useRoute();
  const instance = getCurrentInstance();
  const router = useRouter();
  const auth = useAuth();
  const user = ref(auth.user());
  const pageTitle = computed(() => {
    return demoData.value?.map_name
      ? `Refrag | ${scoreInfo.value[0]} - ${scoreInfo.value[1]} on ${friendlyMapName.value}`
      : `Refrag | Match`;
  });
  const pageEmbedImage = computed(() => {
    return demoData.value?.embed_image || (
      demoData.value?.map_name ? `https://play.refrag.gg/maps/${demoData.value.map_name}_cs2.png` : null
    )
  })
  const showServerModal = ref(false);
  const showMapSelector = ref(false);
  const serverInfo = ref(null);
  const demoData = ref(null);
  const loading = ref(true);
  const activeServer = computed(() => store.getters.activeServer);
  const loadingRound = ref(false);
  const loadingRoundSuccess = ref(false);
  const websocket = ref(null);
  const subscription = ref(null);

  onMounted(() => {
    if(user.value.access_level < 2) {
      router.push('/demo_viewer_upgrade');
      return;
    }
  });

  useHead({
    title: () => pageTitle.value,
    meta: [
      {
        name: "description",
        content:
          "Refrag is your ultimate training tool, designed to enhance your skills, boost your confidence, and turn every gaming session into an epic adventure.",
      },
      {
        name: "og:title",
        content: () => pageTitle.value,
      },
      {
        name: "twitter:card",
        content: "summary_large_image",
      },
      {
        name: "twitter:domain",
        content: "play.refrag.gg",
      },
      {
        name: "og:image",
        content: () => pageEmbedImage.value,
      },
      {
        name: "twitter:image",
        content: () => pageEmbedImage.value,
      },
    ],
  });
  
  function openServerModal(selectedRound) {
    serverInfo.value = {
      mode: 'Restrat',
      cs_map: demoData.value?.cs_map,
      content: {
        id: demoData.value?.id,
        round_id: selectedRound,
      },
    };
    showServerModal.value = true;
  }

  function startDemo(roundId) {
    loadingRound.value = true;
    if (!activeServer.value?.id) {
      openServerModal(roundId);
    } else {
      axios({
        url: `/cs_servers/${activeServer.value.id}/exec_server_command`,
        method: "POST",
        params: {
          command: "load_restrat",
          value: `${demoData.value?.id}, ${roundId}`,
        },
        headers: {
          "X-TEAM-ID": store.getters.activeTeam.id,
        },
      })
        .then(() => {
          console.log("Demo started successfully");
          loadingRound.value = false;
          loadingRoundSuccess.value = true;
          setTimeout(() => {
            loadingRoundSuccess.value = false;
          }, 3000);
        })
        .catch((err) => {
          loadingRound.value = false;
          console.error("Could not communicate with server", err);
          store.commit("showMessage", {
            messageType: "error",
            messageText: "Could not communicate with server",
          });
          setTimeout(() => {
            store.commit("hideMessage");
          }, 3000);
        });
    }
  }
  
  function getDemoData() {
    loading.value = true;
    axios
      .get(`/demos/${route.params.id}`, {
        headers: {
          "X-TEAM-ID": store.getters.activeTeam.id,
        },
      }).then((response) => {
        demoData.value = response.data;
        loading.value = false;
        subscribeToDemo();
      })
      .catch((error) => {
        // TODO: Show an error message to the user
        console.log(error);
        loading.value = false;
      });
  }

  function subscribeToDemo() {
    // Accessing $cable from the instance
    const cable = instance.appContext.config.globalProperties.$cable;

    if (!websocket.value) {
      websocket.value = cable.connect();
    }
    if (!subscription.value) {
      subscription.value = websocket.value.subscriptions.create(
        { channel: "DemoChannel", id: route.params.id },
        {
          received: (data) => {
            getDemoData();
          },
        }
      );
    }
  }

  function goTo2DViewer() {
    router.push(`/demos/${route.params.id}/review`);
  }
  
  getDemoData();
  
  </script>
  
  <style scoped>
  .map-linear-gradient {
    background: linear-gradient(180deg, #080a0d 0%, rgba(8, 10, 13, 0.8) 50%, #080a0d 100%);
  }
  
  .svg-filter {
    filter: invert(73%) sepia(27%) saturate(244%) hue-rotate(175deg) brightness(100%)
      contrast(85%);
  }
  </style>
  