<template>
  <div class="w-full h-full bg-gray-950">
    <Dashboard v-if="user" title="Match Page" no-padding="true">
      <div v-if="matchData && !loading" class="flex flex-col w-full">
        <div class="flex flex-col w-full">
          <div class="w-full relative h-[150px]lg:h-[250px]">
            <div class="absolute left-0 top-0 z-10 h-full w-full map-linear-gradient"></div>
            <img
              class="absolute left-0 top-0 h-full w-full object-cover object-center select-none"
              :src="`/maps/${matchData?.map_name}_cs2.png`"
            />
            <div class="w-full h-full px-4 lg:px-9 pt-6 flex flex-col">
              <div class="w-full grid grid-cols-3 gap-4 items-start">
                <div class="flex gap-2 items-center">
                  <img
                    class="w-3 lg:w-5 h-3 lg:h-5 z-50 select-none"
                    :src="getPlatformLogo(matchData?.platform)"
                  />
                  <span class="text-text-secondary z-50 text-xs lg:text-base">{{
                    toTitleCase(matchData?.match_type)
                  }}</span>
                  <span class="text-text-disabled z-50">•</span>
                  <img
                    class="w-3 lg:w-5 h-3 lg:h-5 z-50 select-none"
                    :src="`/maps/map_icon_${matchData?.map_name}.svg`"
                  />
                  <span class="text-text-secondary z-50 text-xs lg:text-base">{{ friendlyMapName }}</span>
                </div>

                <div
                  v-if="didUserWin !== null && scoreInfo"
                  class="flex flex-col gap-3 items-center justify-center py-6"
                >
                  <div class="flex gap-2">
                    <div
                      v-if="didUserWin"
                      class="text-xs lg:text-sm rounded-md bg-[#12928333] text-success-400 px-2 py-1 z-50"
                    >
                      Victory
                    </div>
                    <div
                      v-else-if="scoreInfo[0] == scoreInfo[1]"
                      class="text-xs lg:text-sm rounded-md bg-[#8B000033] text-danger-300 px-2 py-1 z-50"
                    >
                      Tie
                    </div>
                    <div
                      v-else
                      class="text-xs lg:text-sm rounded-md bg-[#8B000033] text-danger-300 px-2 py-1 z-50"
                    >
                      Defeat
                    </div>
                    <div v-if="matchInvalid" class="flex gap-2 items-center text-xs lg:text-sm rounded-md bg-warning-900 bg-opacity-50 text-warning-400 px-2 py-1 z-50"  title="Match invalidated and will not be used for coach calculations">
                      <ExclamationTriangleIcon class="w-4 h-4" />
                      <span>Match Invalidated</span>
                    </div>
                  </div>

                  <div
                    v-if="didUserWin"
                    class="text-4xl lg:text-[48px] z-50 font-bold text-text-primary flex gap-2 items-center"
                  >
                    <h1 :class="[scoreInfo[0] > scoreInfo[1] ? 'text-success-400' : '']">
                      {{ scoreInfo[0] }}
                    </h1>
                    -
                    <h1 :class="[scoreInfo[0] < scoreInfo[1] ? 'text-success-400' : '']">
                      {{ scoreInfo[1] }}
                    </h1>
                  </div>

                  <div
                    v-if="!didUserWin"
                    class="text-[48px] z-50 font-bold text-text-primary flex gap-2 items-center"
                  >
                    <h1 :class="[scoreInfo[0] < scoreInfo[1] ? 'text-danger-400' : '']">
                      {{ scoreInfo[0] }}
                    </h1>
                    -
                    <h1 :class="[scoreInfo[0] > scoreInfo[1] ? 'text-danger-400' : '']">
                      {{ scoreInfo[1] }}
                    </h1>
                  </div>

                  <div class="text-text-secondary text-xs lg:text-sm z-50 text-nowrap">
                    {{
                      moment(matchData?.played_on || matchData?.date).format(
                        "h:mm A, MMM Do, YYYY"
                      )
                    }}
                  </div>
                </div>

                <div
                  v-else-if="scoreInfo"
                  class="flex flex-col gap-3 items-center justify-center py-6"
                >
                  <div
                    class="text-[48px] z-50 font-bold text-text-primary flex gap-2 items-center"
                  >
                    <h1>{{ scoreInfo[0] }}</h1>
                    -
                    <h1>{{ scoreInfo[1] }}</h1>
                  </div>

                  <div class="text-text-secondary text-sm z-50">
                    {{
                      moment(matchData?.played_on || matchData?.date).format(
                        "h:mm A, MMM Do, YYYY"
                      )
                    }}
                  </div>
                </div>

                <!-- <div class="flex gap-2 items-center justify-end">
                  <button
                    class="py-2.5 px-3 bg-gray-800 text-text-primary text-xs z-50 cursor-pointer hover:bg-gray-700 duration-300 rounded-lg"
                    @click="parseDemo()"
                  >
                    Parse
                  </button>
                </div>
								-->
              </div>

              <div class="w-full">
                <nav
                  class="w-full flex flex-row relative gap-9 border-b border-gray-800 z-50"
                >
                  <div
                    ref="overviewDiv"
                    class="pb-4 text-center cursor-pointer flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start z-50"
                    @click="updateActiveTab('overview')"
                  >
                    <img
                      src="../assets/icons/info-icon.svg"
                      :class="[activeTab === 'overview' ? 'brightness-200' : '']"
                      class="h-5 w-5 duration-300"
                      alt="Info icon"
                    />
                    <h3
                      class="leading-5 duration-300"
                      :class="[
                        activeTab === 'overview'
                          ? 'text-text-primary'
                          : 'text-text-secondary',
                      ]"
                    >
                      Overview
                    </h3>
                  </div>
                  <div
                    ref="statsDiv"
                    class="pb-4 text-center cursor-pointer flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start z-50"
                    @click="updateActiveTab('statistics')"
                  >
                    <img
                      src="../assets/icons/stats-icon.svg"
                      :class="[activeTab === 'statistics' ? 'brightness-200' : '']"
                      class="h-5 w-5 duration-300"
                      alt="Stats icon"
                    />
                    <h3
                      class="leading-5 duration-300"
                      :class="[
                        activeTab === 'statistics'
                          ? 'text-text-primary'
                          : 'text-text-secondary',
                      ]"
                    >
                      Statistics
                    </h3>
                  </div>
                  <div
                    :disabled="filteredHighlights.length === 0"
                    ref="highlightsDiv"
                    class="pb-4 text-center flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start z-50"
                    :class="{ 'cursor-pointer': filteredHighlights.length > 0, '': filteredHighlights.length === 0 }"
                    @click="filteredHighlights.length > 0 && updateActiveTab('highlights')"
                  >
                    <img
                      src="../assets/icons/play.svg"
                      :class="[activeTab === 'highlights' ? 'brightness-200' : '']"
                      class="h-5 w-5 duration-300"
                      alt="Stats icon"
                    />
                    <h3
                      class="leading-5 duration-300"
                      :class="[
                        activeTab === 'highlights'
                          ? 'text-text-primary'
                          : 'text-text-secondary',
                      ]"
                    >
                      Highlights
                    </h3>
                    <span class="text-text-secondary text-xs px-1 py-.5 rounded-md bg-gray-800">
                      {{ filteredHighlights.length }}
                    </span>
                  </div>
                  <div class="absolute flex-row md:flex bottom-0 z-50">
                    <div
                      class="h-[2px] grow-transition"
                      :style="{ width: getSectionUnderlinePadding() + 'px' }"
                    />
                    <div
                      ref="sectionUnderline"
                      class="h-[2px] bg-primary-600 grow-transition"
                      :style="{ width: getSectionUnderlineWidth() + 'px' }"
                    />
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div class="px-4 lg:px-9 py-6">
            <MatchOverview
              v-if="activeTab === 'overview'"
              :match-data="matchData"
              @updateActiveTab="updateActiveTab"
              :active-user-two-lowest="activeUserTwoLowest"
              :active-user-match-data="activeUserMatchData"
              :active-user-data="activeUserData"
              @update-active-user-two-lowest="activeUserTwoLowest = $event"
              @update-active-user-match-data="activeUserMatchData = $event"
              @update-active-user-data="activeUserData = $event"
              @openServerModal="openServerModal"
            />
            <MatchStatistics
              v-else-if="activeTab === 'statistics'"
              :match-data="matchData"
              @updateActiveTab="updateActiveTab"
              :user-data="userData"
              :selected-user="selectedUser"
              :selected-stat="selectedStat"
              :user-match-history="userMatchHistory"
              @update-user-data="userData = $event"
              @update-selected-user="selectedUser = $event"
              @update-selected-stat="selectedStat = $event"
              @update-user-match-history="userMatchHistory = $event"
              @get-match-data="getMatchData"
              @openServerModal="openServerModal"
            />
            <MatchHighlights
              v-else-if="activeTab === 'highlights'"
              :match-data="matchData"
              @updateActiveTab="updateActiveTab"
            />
          </div>
        </div>
      </div>
      <div v-else class="h-full flex justify-center items-center w-full">
        <p v-if="loading" class="text-white">
          <svg
            class="animate-spin h-full font-normal w-12"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </p>
        <p v-else class="text-white">
          <div class="max-w-lg text-white">
          <h1 class="text-3xl font-bold text-center text-gray-200 mb-8">
            404 - Match Not Found
          </h1>
          <p class="text-gray-300 mb-6">
            Oops! It seems you're on the wrong bombsite.
          </p>
          <ul class="text-gray-300 mb-6">
            <li class="mb-2">
              1. <strong>Check your bomb site:</strong> Make sure you planted the right
              URL. Sometimes, a tiny typo can lead to big detours.
            </li>
            <li class="mb-2">
              2. <strong>Reach out to our team:</strong> If you believe this is a glitch
              on our end, our CTs are on high alert to defuse the situation. Just drop us
              a message at
              <a href="mailto:stefan@refrag.gg" class="text-blue-500">stefan@refrag.gg</a
              >, and we'll have our digital detectives investigate right away.
            </li>
            <li>
              3. <strong>Meanwhile, take a breather:</strong> Consider this a technical
              pause. Use this moment to strategize, or better yet, grab a snack and
              recharge for your next round on Refrag.gg.
            </li>
          </ul>
          <p class="text-gray-300 text-lg">
            Remember, even the best of us end up on the wrong bombsite sometimes. With a
            little patience and perseverance, you'll be back to fragging and clutching in
            no time!
          </p>
        </div>
        </p>
      </div>
      <StartServerModal
        :server="serverInfo"
        :show="showServerModal"
        :showMapSelector="showMapSelector"
        @close="showServerModal = false"
      />
    </Dashboard>
    <div v-else class="flex flex-col h-full items-center w-full bg-gray-950">
      <div
        class="w-full min-h-[56px] bg-gray-900 border-b border-gray-800 relative gap-4 flex items-center justify-end pr-9"
      >
        <img
          class="h-6 absolute left-6 top-0 md:left-1/2 md:-translate-x-1/2 translate-y-[16px]"
          src="../assets/logos/refrag-horz-fill.svg"
          alt="Refrag Logo"
        />
        <router-link
          to="/login"
          class="px-3 py-2.5 bg-gray-800 rounded-md text-text-primary text-xs font-medium hover:bg-gray-700"
        >
          Login
        </router-link>
        <router-link
          to="/register"
          class="px-3 py-2.5 bg-primary-600 rounded-md text-text-primary text-xs font-medium hover:bg-primary-500"
        >
          Signup
        </router-link>
      </div>
      <div class="flex flex-col w-full bg-gray-950">
        <div class="w-full relative h-[250px]">
          <div class="absolute left-0 top-0 z-10 h-full w-full map-linear-gradient"></div>
          <img
            class="absolute left-0 top-0 h-full w-full object-cover object-center select-none"
            :src="`/maps/${matchData?.map_name}_cs2.png`"
          />
          <div class="w-full h-full px-9 pt-6 flex flex-col max-w-[1200px] mx-auto">
            <div class="w-full grid grid-cols-3 gap-4 items-start">
              <div v-if="matchData" class="flex gap-2 items-center">
                <img
                  class="w-5 h-5 z-50 select-none"
                  :src="getPlatformLogo(matchData?.platform)"
                />
                <span class="text-text-secondary z-50">{{
                  toTitleCase(matchData?.match_type)
                }}</span>
                <span class="text-text-disabled z-50">•</span>
                <img
                  class="w-5 h-5 z-50 select-none"
                  :src="`/maps/map_icon_${matchData?.map_name}.svg`"
                />
                <span class="text-text-secondary z-50">{{ friendlyMapName }}</span>
              </div>

              <div
                v-if="didUserWin !== null && scoreInfo"
                class="flex flex-col gap-3 items-center justify-center py-6"
              >
                <div class="flex gap-2">
                  <div
                    v-if="didUserWin"
                    class="text-sm rounded-md bg-[#12928333] text-success-400 px-2 py-1 z-50"
                  >
                    Victory
                  </div>
                  <div
                    v-else-if="scoreInfo[0] == scoreInfo[1]"
                    class="text-sm rounded-md bg-[#8B000033] text-danger-300 px-2 py-1 z-50"
                  >
                    Tie
                  </div>
                  <div
                    v-else
                    class="text-sm rounded-md bg-[#8B000033] text-danger-300 px-2 py-1 z-50"
                  >
                    Defeat
                  </div>
                  <div v-if="matchInvalid" class="flex gap-2 items-center text-sm rounded-md bg-warning-900 bg-opacity-50 text-warning-400 px-2 py-1 z-50" title="Match invalidated and will not be used for coach calculations">
                    <ExclamationTriangleIcon class="w-4 h-4" />
                    <span>Match Invalidated</span>
                  </div>
                </div>

                <div
                  v-if="didUserWin"
                  class="text-[48px] z-50 font-bold text-text-primary flex gap-2 items-center"
                >
                  <h1 :class="[scoreInfo[0] > scoreInfo[1] ? 'text-success-400' : '']">
                    {{ scoreInfo[0] }}
                  </h1>
                  -
                  <h1 :class="[scoreInfo[0] < scoreInfo[1] ? 'text-success-400' : '']">
                    {{ scoreInfo[1] }}
                  </h1>
                </div>

                <div
                  v-if="!didUserWin"
                  class="text-[48px] z-50 font-bold text-text-primary flex gap-2 items-center"
                >
                  <h1 :class="[scoreInfo[0] < scoreInfo[1] ? 'text-danger-400' : '']">
                    {{ scoreInfo[0] }}
                  </h1>
                  -
                  <h1 :class="[scoreInfo[0] > scoreInfo[1] ? 'text-danger-400' : '']">
                    {{ scoreInfo[1] }}
                  </h1>
                </div>

                <div class="text-text-secondary text-sm z-50">
                  {{
                    moment(matchData?.played_on || matchData?.date).format(
                      "h:mm A, MMM Do, YYYY"
                    )
                  }}
                </div>
              </div>

              <div
                v-else-if="scoreInfo"
                class="flex flex-col gap-3 items-center justify-center py-6"
              >
                <div
                  class="text-[48px] z-50 font-bold text-text-primary flex gap-2 items-center"
                >
                  <h1>{{ scoreInfo[0] }}</h1>
                  -
                  <h1>{{ scoreInfo[1] }}</h1>
                </div>

                <div class="text-text-secondary text-sm z-50">
                  {{
                    moment(matchData?.played_on || matchData?.date).format(
                      "h:mm A, MMM Do, YYYY"
                    )
                  }}
                </div>
              </div>

              <div class="flex gap-2 items-center justify-end">
                <button
                  class="py-2.5 px-3 bg-gray-800 text-text-primary text-xs z-50 cursor-pointer hover:bg-gray-700 duration-300 rounded-lg"
                >
                  Watch replay
                </button>
                <button
                  class="py-2.5 px-3 bg-gray-800 text-text-primary text-xs z-50 cursor-pointer hover:bg-gray-700 duration-300 rounded-lg"
                >
                  Share
                </button>
              </div>
            </div>

            <div class="w-full">
              <nav
                class="w-full flex flex-row relative gap-9 border-b border-gray-800 z-50"
              >
                <div
                  ref="overviewDiv"
                  class="pb-4 text-center cursor-pointer flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start z-50"
                  @click="updateActiveTab('overview')"
                >
                  <img
                    src="../assets/icons/info-icon.svg"
                    :class="[activeTab === 'overview' ? 'brightness-200' : '']"
                    class="h-5 w-5 duration-300"
                    alt="Info icon"
                  />
                  <h3
                    class="leading-5 duration-300"
                    :class="[
                      activeTab === 'overview'
                        ? 'text-text-primary'
                        : 'text-text-secondary',
                    ]"
                  >
                    Overview
                  </h3>
                </div>
                <div
                  ref="statsDiv"
                  class="pb-4 text-center cursor-pointer flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start z-50"
                  @click="updateActiveTab('statistics')"
                >
                  <img
                    src="../assets/icons/stats-icon.svg"
                    :class="[activeTab === 'statistics' ? 'brightness-200' : '']"
                    class="h-5 w-5 duration-300"
                    alt="Stats icon"
                  />
                  <h3
                    class="leading-5 duration-300"
                    :class="[
                      activeTab === 'statistics'
                        ? 'text-text-primary'
                        : 'text-text-secondary',
                    ]"
                  >
                    Statistics
                  </h3>
                </div>
                <div
                  :disabled="filteredHighlights.length === 0"
                  ref="highlightsDiv"
                  class="pb-4 text-center flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start z-50"
                  :class="{ 'cursor-pointer': filteredHighlights.length > 0, '': filteredHighlights.length === 0 }"
                  @click="filteredHighlights.length > 0 && updateActiveTab('highlights')"
                >
                  <img
                    src="../assets/icons/play.svg"
                    :class="[activeTab === 'highlights' ? 'brightness-200' : '']"
                    class="h-5 w-5 duration-300"
                    alt="Stats icon"
                  />
                  <h3
                    class="leading-5 duration-300"
                    :class="[
                      activeTab === 'highlights'
                        ? 'text-text-primary'
                        : 'text-text-secondary',
                    ]"
                  >
                    Highlights
                  </h3>
                  <span class="text-text-secondary text-xs px-1 py-.5 rounded-md bg-gray-800">
                    {{ filteredHighlights.length }}
                  </span>
                </div>
                <div class="absolute flex-row md:flex bottom-0 z-50">
                  <div
                    class="h-[2px] grow-transition"
                    :style="{ width: getSectionUnderlinePadding() + 'px' }"
                  />
                  <div
                    ref="sectionUnderline"
                    class="h-[2px] bg-primary-600 grow-transition"
                    :style="{ width: getSectionUnderlineWidth() + 'px' }"
                  />
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="px-9 py-6 max-w-[1200px] mx-auto w-full">
          <MatchOverview
            v-if="activeTab === 'overview'"
            :match-data="matchData"
            @updateActiveTab="updateActiveTab"
            :active-user-two-lowest="activeUserTwoLowest"
            :active-user-match-data="activeUserMatchData"
            :active-user-data="activeUserData"
            @update-active-user-two-lowest="activeUserTwoLowest = $event"
            @update-active-user-match-data="activeUserMatchData = $event"
            @update-active-user-data="activeUserData = $event"
            @openServerModal="openServerModal"
          />
          <MatchStatistics
            v-else-if="activeTab === 'statistics'"
            :match-data="matchData"
            @updateActiveTab="updateActiveTab"
            :user-data="userData"
            :selected-user="selectedUser"
            :selected-stat="selectedStat"
            :user-match-history="userMatchHistory"
            @update-user-data="userData = $event"
            @update-selected-user="selectedUser = $event"
            @update-selected-stat="selectedStat = $event"
            @update-user-match-history="userMatchHistory = $event"
            @get-match-data="getMatchData"
            @openServerModal="openServerModal"
          />
          <MatchHighlights
            v-else-if="activeTab === 'highlights'"
            :match-data="matchData"
            @updateActiveTab="updateActiveTab"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import Dashboard from "../components/layouts/DashboardLayout.vue";
import MatchOverview from "../components/Matches/MatchOverview.vue";
import MatchStatistics from "../components/Matches/MatchStatistics.vue";
import MatchHighlights from "../components/Matches/MatchHighlights.vue";
import MatchRestrat from "../components/Matches/MatchRestrat.vue";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { data } from "autoprefixer";
import steamLogo from "../assets/icons/steam.svg";
import faceitLogo from "../assets/icons/faceit.svg";
import moment from "moment";
import { useHead } from "@unhead/vue";
import { onMounted } from "vue";
import StartServerModal from "../components/StartServerModal.vue";
import { load } from "protobufjs";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";

const activeTab = ref("overview");
const overviewDiv = ref();
const store = useStore();
const statsDiv = ref();
const highlightsDiv = ref();
const restratDiv = ref();
const matchData = ref(null);
const sectionUnderlineWidth = ref(92);
const sectionUnderlinePadding = ref(0);
const route = useRoute();
const router = useRouter();
const auth = useAuth();
const user = ref(auth.user());
const userData = ref(null);
const selectedUser = ref(null);
const selectedStat = ref(null);
const userMatchHistory = ref(null);
const activeUserTwoLowest = ref(null);
const activeUserMatchData = ref(null);
const activeUserData = ref(null);
const didUserWin = ref(null);
const matchInvalid = computed(() => {
  return matchData.value?.invalid_match;
})
const pageTitle = computed(() => {
  return matchData.value?.map_name
    ? `Refrag | ${scoreInfo.value[0]} - ${scoreInfo.value[1]} on ${friendlyMapName.value}`
    : `Refrag | Match`;
});
const pageEmbedImage = computed(() => {
  return matchData.value?.embed_image || (
    matchData.value?.map_name ? `https://play.refrag.gg/maps/${matchData.value.map_name}_cs2.png` : null
  )
})
const showServerModal = ref(false);
const showMapSelector = ref(false);
const serverInfo = ref(null);
const loading = ref(true);
const friendlyMapName = computed(() => {
  if (!matchData.value?.map_name) return "unknown";
  const parts = matchData.value.map_name.split('_');
  if (parts.length > 1) {
    parts[1] = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
    const lastChar = parts[parts.length - 1].charAt(parts[parts.length - 1].length - 1);
    if (!isNaN(lastChar)) {
      parts[parts.length - 1] = parts[parts.length - 1].slice(0, -1) + ' ' + lastChar;
    }
    return parts.slice(1).join(' ');
  }
  return matchData.value?.map_name ? matchData.value.map_name.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase()) : null;
})

useHead({
  title: () => pageTitle.value,
  meta: [
    {
      name: "description",
      content:
        "Refrag is your ultimate training tool, designed to enhance your skills, boost your confidence, and turn every gaming session into an epic adventure.",
    },
    {
      name: "og:title",
      content: () => pageTitle.value,
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:domain",
      content: "play.refrag.gg",
    },
    {
      name: "og:image",
      content: () => pageEmbedImage.value,
    },
    {
      name: "twitter:image",
      content: () => pageEmbedImage.value,
    },
  ],
});

const filteredHighlights = computed(() => {
  return matchData.value?.highlights?.filter(
    (highlight) => highlight.status !== "created" && highlight.status !== "failed"
  );
});

const scoreInfo = computed(() => {
  if (matchData.value) {
    const scoreArray = parseScore(matchData.value.score);
    return scoreArray;
  }
});

function openServerModal(selectedStat) {
  const maps = store.state.modInfo?.mods?.find(mod => mod?.name?.toLowerCase() === selectedStat.recommended_mods[0].modifier.modifier_name?.toLowerCase())?.cs_maps || []
  serverInfo.value = {
    mode: selectedStat.recommended_mods[0].modifier.modifier_name,
    cs_map: maps.find(map => map.name === matchData.value?.map_name),
    time_to_kill: activeUserData.value?.stats?.time_to_kill?.players_value || null,
  };
  showMapSelector.value = true;
  showServerModal.value = true;
}

function parseScore(scoreString) {
  return scoreString.split("-").map(Number);
}

function updateActiveTab(value) {
  activeTab.value = value;
  if (value === "overview") {
    router.push({ query: {} });
  } else {
    router.push({ query: { tab: value } });
  }
  updateUnderlineStyle();
}

function updateUnderlineStyle() {
  if (activeTab.value === "statistics") {
    sectionUnderlineWidth.value = statsDiv.value?.offsetWidth;
    sectionUnderlinePadding.value = statsDiv.value?.offsetLeft;
  } else if (activeTab.value === "highlights") {
    sectionUnderlineWidth.value = highlightsDiv.value?.offsetWidth;
    sectionUnderlinePadding.value = highlightsDiv.value?.offsetLeft;
  } else if (activeTab.value === "restrat") {
    sectionUnderlineWidth.value = restratDiv.value?.offsetWidth;
    sectionUnderlinePadding.value = restratDiv.value?.offsetLeft;
  } else if (activeTab.value === "overview") {
    sectionUnderlineWidth.value = overviewDiv.value?.offsetWidth;
    sectionUnderlinePadding.value = overviewDiv.value?.offsetLeft;
  }
}

function getSectionUnderlineWidth() {
  return sectionUnderlineWidth.value;
}

function getSectionUnderlinePadding() {
  return sectionUnderlinePadding.value;
}

function getMatchData() {
  loading.value = true;
  axios
    .get(`/matches/parsed_match`, {
      params: {
        match_id: route.params.id,
      },
    })
    .then((response) => {
      matchData.value = response.data;

      if (!selectedUser.value) {
        const userSteamId = user?.value?.steam_id || null;
        const userIndex = response.data.players.findIndex(
          (player) => player?.player?.steam_id == userSteamId
        );
        if (userIndex !== -1) {
          selectedUser.value = response.data.players[userIndex];
        } else {
          selectedUser.value = response.data.players[0];
        }
      }
      updateUserWinInfo();
      getActiveUserData();
    })
    .catch((error) => {
      // TODO: Show an error message to the user
      console.log(error);
      loading.value = false;
    });
}

function updateUserWinInfo() {
  const userSteamId = user?.value?.steam_id || null;
  const userIndex = matchData.value.players.findIndex(
    (player) => player?.player?.steam_id == userSteamId
  );
  if (userIndex !== -1) {
    didUserWin.value =
      matchData.value.players[userIndex].match_performance.last_round_team ==
      matchData.value.winning_team;
  } else {
    didUserWin.value = null;
  }
}

function getActiveUserData() {
  loading.value = true;

  if (!user.value) {
    loading.value = false;
    return;
  }

  const userId = user.value.steam_id;

  const userIndex = matchData.value.players.findIndex(
    (player) => player.player.steam_id == user.value.steam_id
  );
  if (userIndex === -1) {
    loading.value = false;
    return;
  }

  const map_name =
    matchData.value.match_type === "competitive" ? matchData.value.map_name : "";

  axios
    .get(`/matches/user_profile`, {
      params: {
        steam_id: userId,
        platform: matchData.value.platform,
        match_type: matchData.value.match_type,
      },
    })
    .then((response) => {
      activeUserData.value = response.data;
      loading.value = false;
    })
    .catch((error) => {
      loading.value = false;
    });
}

function parseDemo() {
  axios
    .post(`/matches/parse_demo`, {
      match_id: route.params.id,
    })
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function getPlatformLogo(platform) {
  if (platform === "faceit") {
    return faceitLogo;
  } else {
    return steamLogo;
  }
}

if (route.query.tab) {
  updateActiveTab(route.query.tab);
}

getMatchData();
</script>

<style scoped>
.map-linear-gradient {
  background: linear-gradient(180deg, #080a0d 0%, rgba(8, 10, 13, 0.8) 50%, #080a0d 100%);
}

.svg-filter {
  filter: invert(73%) sepia(27%) saturate(244%) hue-rotate(175deg) brightness(100%)
    contrast(85%);
}
</style>
