<template>
  <div class="flex flex-col gap-4 sm:justify-start h-full">
    <div class="bg-gray-900 rounded-lg p-4 flex flex-col gap-2 flex-grow items-start">
      <div class="flex gap-2 w-full justify-between">
        <div
          class="w-16 h-16 bg-gray-800 border border-gray-700 rounded-full flex items-center justify-center mb-2"
        >
          <img src="../../assets/icons/routines.svg" class="w-8 h-8" />
        </div>

        <button
          @click="router.push(`/routines/${selectedRoutine.slug}`)"
          class="relative cursor-pointer inline-flex items-center justify-center h-8 w-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-gray-800 hover:bg-gray-700"
        >
          <img
            src="../../assets/icons/rightarrow.svg"
            class="w-4 h-4 text-text-primary"
          />
        </button>
      </div>

      <div
        v-if="selectedRoutine?.completed"
        class="flex gap-1 py-1 px-2 rounded-lg bg-gray-800 items-center justify-start"
      >
        <div class="w-3 h-3 rounded-full border border-gray-700 bg-success-500"></div>
        <h2 class="text-text-secondary text-xs font-light">Completed today</h2>
      </div>
      <div
        v-else
        class="flex gap-1 py-1 px-2 rounded-lg bg-gray-800 items-center justify-start"
      >
        <div class="w-3 h-3 rounded-full border border-gray-700"></div>
        <h2 class="text-text-secondary text-xs font-light">Not completed today</h2>
      </div>

      <div class="flex flex-col gap-1 pb-3 flex-1">
        <h1 class="text-text-primary font-medium text-sm">
          {{ selectedRoutine?.name }}
        </h1>
        <h2 class="text-text-secondary text-xs font-light flex-1">
          {{ selectedRoutine?.description }}
        </h2>
      </div>

      <div class="flex flex-col gap-2 w-full">
        <Menu as="div" class="relative inline-block text-left w-full">
          <MenuButton
            class="relative w-full disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
          >
            <div class="flex-1 text-left font-light">
              <span class="text-text-secondary"
                >{{ selectedLength }} • {{ getTimeLength(selectedLength) }}min</span
              >
            </div>
            <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-50 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1 flex flex-col px-4">
                <MenuItem
                  :key="length"
                  v-for="length in routineLengths"
                  v-slot="{ active }"
                  @click="selectedLength = length"
                >
                  <a
                    href="#"
                    :class="[
                      active ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start',
                    ]"
                  >
                    {{ length }} • {{ getTimeLength(length) }}min
                    <CheckIcon
                      v-if="length === selectedLength"
                      class="h-5 w-5 ml-auto text-primary-400"
                    />
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>

        <button
          id="launchCoachRoutine"
          @click="launchRoutine(selectedRoutine)"
          class="mt-auto py-2 w-full rounded-lg text-text-primary text-xs font-medium duration-300 bg-primary-600 hover:bg-primary-500 flex items-center justify-center gap-2"
        >
          Launch Routine
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="js">
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { ChevronDownIcon, CheckIcon } from "@heroicons/vue/20/solid";
import { lengthToTimeMap } from "../../utils/coach_routines";

const router = useRouter();

const props = defineProps({
  routines: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["launchRoutine"]);

const routineLengths = computed(() => {
  return props.routines.sort((a, b) => a.duration_in_minutes - b.duration_in_minutes).map(routine => timeToLengthMap.value[routine.duration_in_minutes]);
});

const selectedLength = ref("Short");

function getTimeLength(length) {
    return lengthToTimeMap[length];
}

function launchRoutine(routine) {
  emit("launchRoutine", routine);
}

const timeToLengthMap = computed(() => {
  return Object.entries(lengthToTimeMap).reduce((acc, [length, time]) => {
    acc[time] = length;
    return acc;
  }, {});
});


const selectedRoutine = computed(() => {
  return props.routines?.find(routine => {
    return routine.duration_in_minutes === lengthToTimeMap[selectedLength.value];
  });
});
</script>
