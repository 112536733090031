<template>
  <Dashboard>
    <div v-if="!playerStats?.kills || user.access_level < 1" class="flex flex-col gap-4">
      <div class="flex flex-col gap-4">
        <h1 class="text-4xl font-medium text-text-primary">Refrag Coach</h1>
        <p class="text-text-secondary text-sm font-light w-2/3">
          Track your progress with detailed performance insights, designed to help you dominate the competition.
        </p>

        <div v-if="error || user.access_level < 1" class="flex flex-col gap-4">
          <div
            class="flex-1 flex flex-col gap-2 w-full bg-gray-900 rounded-lg p-10 items-center justify-center relative"
          >
            <div
              v-if="!showMatches"
              class="flex flex-col gap-4 text-center items-center z-50"
            >
              <div
                class="min-w-[48px] w-[48px] h-[48px] rounded-full flex justify-center items-center button-bg"
              >
                <img class="w-6 h-6" src="../assets/icons/stats-icon.svg" />
              </div>
              <h1 class="text-text-primary font-medium text-lg">
                Refrag Coach is currently disabled
              </h1>
              <h2 class="text-text-secondary text-sm leading-5">
                Refrag Coach is currently disabled due to a site update. <br />Check out our
                <a href="https://discord.gg/refrag" target="_blank" class="underline"
                  >Discord</a
                >
                for updates!
              </h2>
            </div>
            <div
              v-else-if="user.access_level < 1"
              class="flex flex-col gap-4 text-center items-center z-50"
            >
              <div
                class="min-w-[48px] w-[48px] h-[48px] rounded-full flex justify-center items-center button-bg"
              >
                <img class="w-6 h-6" src="../assets/icons/stats-icon.svg" />
              </div>
              <h1 class="text-text-primary font-medium text-lg">
                Unlock Your Performance Insights!
              </h1>
              <h2 class="text-text-secondary text-sm leading-5">
                Get actionable insights, track your progress, and see detailed
                <br />breakdowns that will help you elevate your game.
              </h2>
              <button
                @click="router.push('/upgrade')"
                class="py-3 w-2/3 rounded-lg text-text-primary text-xs font-medium duration-300 bg-primary-600 hover:bg-primary-500 flex items-center justify-center gap-2"
              >
                Upgrade
                <ArrowRightIcon class="w-4 h-4 text-text-primary" />
              </button>
            </div>
            <div
              v-else-if="
                (userSettings?.has_valve_matches && userSettings?.match_auth_token) ||
                userSettings?.faceit_player_id
              "
              class="flex flex-col gap-4 text-center items-center z-50"
            >
              <h1 class="text-text-primary font-medium text-lg">Play more matches!</h1>
              <h2 class="text-text-secondary text-sm leading-5">
                Play more matches in order for the Refrag Coach
                <br />
                to start giving you recommendations for your performance!
                <br />
                If this is wrong,
                <a
                  href="https://discord.com/invite/Refrag"
                  target="_blank"
                  class="underline"
                  >contact support</a
                >
              </h2>
            </div>
            <div
              v-else-if="
                (!userSettings?.has_valve_matches || !userSettings?.match_auth_token) &&
                !userSettings?.faceit_player_id
              "
              class="flex flex-col gap-4 text-center items-center z-50"
            >
              <h1 class="text-text-primary font-medium text-lg">Update your tokens!</h1>
              <h2 class="text-text-secondary text-sm leading-5">
                Add your match token, steam auth code, and Faceit username <br />to start
                analyzing your performance with Refrag Coach.
              </h2>
              <button
                @click="router.push('/settings')"
                class="py-3 w-2/3 rounded-lg text-text-primary text-xs font-medium duration-300 bg-primary-600 hover:bg-primary-500 flex items-center justify-center gap-2"
              >
                Go to Settings
                <ArrowRightIcon class="w-4 h-4 text-text-primary" />
              </button>
            </div>
          </div>
        </div>
        <svg v-else class="w-16 h-16 text-white animate-spin" fill="none" viewBox="0 0 24 24">
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <div v-else class="flex flex-col gap-16">
      <div class="flex flex-col gap-4">
        <h1 class="text-4xl font-medium text-text-primary">Refrag Coach</h1>
        <p class="text-text-secondary text-sm font-light w-2/3">
          Track your progress with detailed performance insights, designed to help you dominate the competition.
        </p>
      </div>

      <div class="flex flex-col gap-4 -mt-8">
        <h1 class="text-2xl font-medium text-text-primary">Overview</h1>
        <div class="gap-6 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
          <div v-if="orderedProgress?.length > 0" class="flex flex-col bg-gray-900 rounded-lg overflow-hidden flex-1 divide-y divide-gray-950 col-span-1">
            <div class="flex flex-col gap-2 p-6 flex-1 green-gradient">
              <h1 class="text-lg font-medium text-text-primary flex-1 flex gap-2 items-start">
                <div class="flex gap-2 items-center">
                  Highlights
                  <div class="has-tooltip">
                    <img src="../assets/icons/info-icon.svg" class="w-4 h-4">
                    <span
                      class="tooltip rounded shadow-md text-left p-3 bg-gray-800 text-white text-xs -mt-8 ml-5"
                      >Highlights show your most improved stat when comparing the last 10 matches to the previous 10</span
                    >
                  </div>
                </div>

              </h1>
              <p class="text-text-secondary text-sm font-light">You recently improved your {{ formatStatString(orderedProgress[0]?.title) }} by {{ formatDataString(parseFloat(orderedProgress[0]?.improvement), orderedProgress[0]?.unit_type) }} and {{ formatStatString(orderedProgress[1]?.title) }} by {{ formatDataString(parseFloat(orderedProgress[1]?.improvement), orderedProgress[1]?.unit_type) }}! Great job! 🔥 </p>
            </div>
            <div class="flex gap-2 px-6 py-4 items-center">
              <h1 class="text-text-secondary flex-1">{{ toTitleCase(formatStatString(orderedProgress[0]?.title)) }}</h1>
              <p class="text-success-400 text-xs">
                {{ orderedProgress[0]?.is_ascending ? '+' : '-' }}{{ formatDataString(parseFloat(orderedProgress[0]?.improvement), orderedProgress[0]?.unit_type) }}
              </p>
              <p class="text-text-primary text-lg">{{ formatDataString(orderedProgress[0]?.value, orderedProgress[0]?.unit_type) }}</p>
            </div>
            <div class="flex gap-2 px-6 py-4 items-center">
              <h1 class="text-text-secondary flex-1">{{ toTitleCase(formatStatString(orderedProgress[1]?.title)) }}</h1>
              <p class="text-success-400 text-xs">
                {{ orderedProgress[1]?.is_ascending ? '+' : '-' }}{{ formatDataString(parseFloat(orderedProgress[1]?.improvement), orderedProgress[1]?.unit_type) }}
              </p>
              <p class="text-text-primary text-lg">{{ formatDataString(orderedProgress[1]?.value, orderedProgress[1]?.unit_type) }}</p>
            </div>
          </div>

          <div v-else class="flex flex-col bg-gray-900 rounded-lg overflow-hidden flex-1 divide-y divide-gray-950 col-span-1">
            <div class="flex flex-col gap-2 p-6 flex-1 green-gradient">
              <h1 class="text-lg font-medium text-text-primary flex gap-2 flex-1">
                Highlights
                <div class="has-tooltip">
                  <img src="../assets/icons/info-icon.svg" class="w-4 h-4">
                  <span
                    class="tooltip rounded shadow-md text-left p-3 bg-gray-800 text-white text-xs -mt-8 ml-5"
                    >Highlights show your most improved stat when comparing the last 10 matches to the previous 10</span
                  >
                </div>
              </h1>
              <p class="text-text-secondary text-sm font-light">No recent progress found, play more matches to see your improvements over time!</p>
            </div>
          </div>

          <div class="flex flex-col bg-gray-900 rounded-lg flex-1 divide-y divide-gray-950 col-span-1">
            <div class="flex gap-2 p-6 flex-1">
              <div class="flex flex-col gap-1 flex-1">
                <h1 class="text-sm text-text-secondary">Matches</h1>
                <p class="text-text-primary">{{ extraUserStats?.match_count }}</p>
              </div>
              <div class="flex flex-col gap-1 flex-1">
                <h1 class="text-sm text-text-secondary">Win rate</h1>
                <p class="text-text-primary">{{ (extraUserStats?.win_rate * 100).toFixed(0) }}% </p>
              </div>
              <div class="flex flex-col gap-1 flex-1">
                <h1 class="text-sm text-text-secondary text-nowrap">Recent Results</h1>
                <div class="flex gap-1">
                  <h1 v-for="(match, index) in [...(userMatchHistory?.slice(0,5) || [])].reverse()" :key="index" :class="match.match_result === 1 ? 'text-success-400' : 'text-danger-400'">{{ match.match_result === 1 ? 'W' : 'L' }}</h1>
                </div>
              </div>
            </div>
            <div class="flex gap-2 px-6 py-4 items-center">
              <h1 class="text-text-secondary flex-1">K/D Ratio</h1>
              <p class="text-text-primary text-lg">{{ calculateKD(playerStats?.kills.players_value, playerStats?.deaths.players_value) }}</p>
            </div>
            <div class="flex gap-2 px-6 py-4 items-center">
              <h1 class="text-text-secondary flex-1">Headshot %</h1>
              <p class="text-text-primary text-lg">{{ (playerStats?.headshot_kill_percentage.players_value * 100).toFixed(0) }}%</p>
            </div>
            <div class="flex gap-2 px-6 py-4 items-center">
              <h1 class="text-text-secondary flex-1">ADR</h1>
              <p class="text-text-primary text-lg">{{ parseFloat(playerStats?.average_damage_per_round.players_value.toFixed(1)) }}</p>
            </div>
          </div>
          
          <Routine :routines="dailyRoutines" @launchRoutine="launchRoutine" class="h-auto flex col-span-1 md:col-span-2 xl:col-span-1"/>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex gap-2 items-center">
          <h1 class="text-text-primary text-2xl font-semibold flex">Weaknesses</h1>
          <span
            class="w-6 h-6 flex items-center justify-center text-danger-400 bg-danger-900 rounded-md"
            >{{ Math.min(lowestStats.length, 4) }}</span
          >
        </div>

        <WeaknessessSection
          :lowestStats="lowestStats"
          :selectedWeaknessStat="selectedWeaknessStat"
          :updateSelectedWeaknessStat="updateSelectedWeaknessStat"
          :user="user"
          :userMatchHistory="userMatchHistory"
          :toTitleCase="toTitleCase"
          :formatStatString="formatStatString"
          :formatDataString="formatDataString"
          :options="options"
          :getChartData="getChartData"
          :launchMod="launchMod"
        />
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex gap-2 items-center">
          <h1 class="text-text-primary text-2xl font-semibold flex">Strengths</h1>
          <span
            class="w-6 h-6 flex items-center justify-center text-success-400 bg-success-900 rounded-md"
            >{{ Math.min(positiveStats.length, 3) }}</span
          >
        </div>

        <div v-if="positiveStats?.length > 0" class="grid grid-cols-1 lg:grid-cols-3 w-full gap-6">
          <StatBlock
            v-for="stat in positiveStats.slice(0, 3)"
            :key="stat.title"
            :stat="stat"
            :style="'strength'"
            :show-improve-button="false"
          />
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <h1 class="text-text-primary text-2xl font-semibold flex">Maps</h1>

        <div class="grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-6">
          <div class="flex flex-col gap-3 flex-1">

            <!-- best map panel -->
            <div class="flex flex-col flex-1">
              <h1 class="text-sm font-light bg-success-900 p-2 text-success-400 text-center rounded-t-lg">Best</h1>
              <div class="flex flex-col gap-6 p-3 relative rounded-b-lg" :style="`background-image: url('/maps/${bestMap?.map_name?.toLowerCase()}_xl.jpeg'); background-size: cover; background-position: center; background-repeat: no-repeat; width: 100%; height: 100%;`">
                <div class="absolute top-0 left-0 w-full h-full bg-black image-gradient opacity-90"></div>
                <div class="flex gap-3 z-50 items-center">
                  <img :src="`/maps/map_icon_${bestMap?.map_name?.toLowerCase()}.svg`" class="w-10 h-10" />
                  <h1 class="text-text-primary">{{ bestMap?.map_name_friendly }}</h1>
                </div>
                
                <div class="flex flex-col gap-3 z-50">
                  <div class="flex gap-2 flex-1">
                    <h1 class="text-text-secondary flex-1 text-sm">Matches</h1>
                    <h1 class="text-text-primary">{{ bestMap?.match_count }}</h1>
                  </div>
                  <div class="flex gap-2 flex-1">
                    <h1 class="text-text-secondary flex-1 text-sm">Win Rate</h1>
                    <h1 class="text-text-primary">{{ (bestMap?.win_rate * 100).toFixed(0) }}%</h1>
                  </div>
                </div>
              </div>
            </div>

            <!-- worst map panel -->
            <div class="flex flex-col">
              <h1 class="text-sm font-light bg-danger-900 p-2 text-danger-400 text-center rounded-t-lg">Worst</h1>
              <div class="flex flex-col gap-6 p-3 relative rounded-b-lg" :style="`background-image: url('/maps/${worstMap?.map_name?.toLowerCase()}_xl.jpeg'); background-size: cover; background-position: center; background-repeat: no-repeat; width: 100%; height: 100%;`">
                <div class="absolute top-0 left-0 w-full h-full bg-black image-gradient opacity-90"></div>
                <div class="flex gap-3 z-50 items-center">
                  <img :src="`/maps/map_icon_${worstMap?.map_name?.toLowerCase()}.svg`" class="w-10 h-10" />
                  <h1 class="text-text-primary">{{ worstMap?.map_name_friendly }}</h1>
                </div>
                
                <div class="flex flex-col gap-3 z-50">
                  <div class="flex gap-2 flex-1">
                    <h1 class="text-text-secondary flex-1 text-sm">Matches</h1>
                    <h1 class="text-text-primary">{{ worstMap?.match_count }}</h1>
                  </div>
                  <div class="flex gap-2 flex-1">
                    <h1 class="text-text-secondary flex-1 text-sm">Win Rate</h1>
                    <h1 class="text-text-primary">{{ (worstMap?.win_rate * 100).toFixed(0) }}%</h1>
                  </div>
                </div>

                <button 
                  @click="router.push(`/bootcamp/${getBootcamp(worstMap?.map_name)}`)"
                  class="text-text-primary bg-primary-600 hover:bg-primary-500 duration-300 z-50 text-sm px-4 py-2 rounded-lg flex justify-center items-center gap-2"
                >
                  <h1>Master this map</h1>
                  <img src="../assets/icons/rightarrow.svg" class="w-4 h-4 brightness-200" />
                </button>
              </div>
            </div>

          </div>

          <!-- map list -->
          <div class="divide-y divide-gray-950 bg-gray-900 rounded-lg col-span-2 overflow-y-auto flex-1">
            <table class="w-full divide-y divide-gray-950">
              <thead>
                <tr>
                  <th class="text-text-primary font-medium text-left px-4 py-3 flex-1 flex gap-2 items-center">Maps</th>
                  <th class="text-text-secondary font-medium tracking-[1.5px] text-xs uppercase text-right px-4 py-3">
                    <div class="flex gap-2 items-center justify-end">
                      Performance
                      <div class="has-tooltip">
                        <img src="../assets/icons/info-icon.svg" class="w-4 h-4">
                        <span
                          class="tooltip rounded shadow-md text-left p-3 bg-gray-800 text-white text-xs -mt-10 ml-2"
                          >Performance is based on your average HLTV rating on each map</span
                        >
                      </div>
                    </div>
                  </th>
                  <th class="text-text-secondary font-medium tracking-[1.5px] text-xs uppercase text-right px-4 py-3">Matches</th>
                  <th class="text-text-secondary font-medium tracking-[1.5px] text-xs uppercase text-right px-4 py-3">Win Rate</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-950 w-full">
                <tr v-for="(map, index) in mapStats" :key="index" class="p-4 w-full gap-2 items-center">
                  <td class="text-text-secondary text-left p-4 flex gap-2 items-center flex-1">
                    <img :src="`/maps/map_icon_${map.map_name.toLowerCase()}.svg`" class="w-6 h-6" />
                    {{ map.map_name_friendly }}
                  </td>
                  <td class="text-right p-4"
                    :class="{
                      'text-danger-400': getPerformance(map) === 'Poor',
                      'text-text-primary': getPerformance(map) === 'Average', 
                      'text-success-400': getPerformance(map) === 'Good' || getPerformance(map) === 'Very Good'
                    }"
                  >
                    {{ getPerformance(map) }}
                  </td>
                  <td class="text-text-primary text-right p-4">
                    {{ map.match_count }}
                  </td>
                  <td class="text-right p-4"
                    :class="{
                      'text-success-400': map.win_rate > 0.5,
                      'text-text-primary': map.win_rate == 0.5,
                      'text-danger-400': map.win_rate < 0.5,
                    }"
                  >
                    {{ (map.win_rate * 100).toFixed(0) }}%
                  </td>
                </tr>
                <tr v-for="(map, index) in statlessMaps" :key="index">
                  <td class="text-text-secondary text-left p-4 flex gap-2 items-center flex-1">
                    <img :src="`/maps/map_icon_${map.name.toLowerCase()}.svg`" class="w-6 h-6" />
                    {{ map.friendly_name }}
                  </td>
                  <td class="text-text-secondary text-right p-4">
                    N/A
                  </td>
                  <td class="text-text-secondary text-right p-4">
                    N/A
                  </td>
                  <td class="text-text-secondary text-right p-4">
                    N/A
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <h1 class="text-text-primary text-2xl font-semibold flex">All Statistics</h1>

        <div class="grid lg:grid-cols-2 w-full gap-6">
          <StatComparisonBlock
            title="General"
            :stats="[
              { label: 'Kills', stat: playerStats.kills },
              { label: 'Assists', stat: playerStats.assists },
              { label: 'Deaths', stat: playerStats.deaths },
              { label: 'Kills Per Round', stat: playerStats.kills_per_round },
              { label: 'HS Kill %', stat: playerStats.headshot_kill_percentage },
              { label: 'ADR', stat: playerStats.average_damage_per_round },
              { label: 'HLTV Rating', stat: playerStats.hltv_rating },
            ]"
          />
          <StatComparisonBlock
            title="Aim"
            :stats="[
              { label: 'Time To Kill', stat: playerStats.time_to_kill },
              { label: 'Time To Damage', stat: playerStats.time_to_damage },
              { label: 'Spotted Accuracy', stat: playerStats.spotted_accuracy },
              { label: 'Headshot %', stat: playerStats.headshot_percentage },
              { label: 'Counter Strafe %', stat: playerStats.shots_at_low_velocity_rate },
              { label: 'Crosshair Placement', stat: playerStats.crosshair_placement },
              { label: 'Recoil Control Accuracy', stat: playerStats.recoil_control_accuracy },
            ]"
          />
          <StatComparisonBlock
            title="Utility"
            :stats="[
              { label: 'Flash Assists', stat: playerStats.flash_assists },
              { label: 'Enemies Flashed', stat: playerStats.enemies_flashed },
              { label: 'Friends Flashed', stat: playerStats.friends_flashed },
              { label: 'Average Flash Time', stat: playerStats.average_flash_time },
              { label: 'Utility Damage', stat: playerStats.utility_damage },
              { label: 'Unused Utility Value', stat: playerStats.unused_utility_value },

            ]"
          />
          <StatComparisonBlock
            title="Entry & Trades"
            :stats="[
              { label: 'Opening Kill Successes', stat: playerStats.opening_success },
              { label: 'Opening Kill Fails', stat: playerStats.opening_fails },
              { label: 'Trade Kill Successes', stat: playerStats.trade_kill_successes },
              { label: 'Trade Kill Fails', stat: playerStats.trade_kill_fails },
              { label: 'Trade Death Successes', stat: playerStats.traded_death_successes },
              { label: 'Trade Death Fails', stat: playerStats.traded_death_fails },
            ]"
          />
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex w-full gap-4 items-center">
          <h1 class="text-text-primary font-semibold text-[24px] flex-1">Match History</h1>
          <svg
            v-if="loadingMatches"
            width="16"
            height="16"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="animate-spin"
          >
            <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
            <path
              d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
              stroke="#4E54C8"
              stroke-width="2"
              />
          </svg>
          <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
            <MenuButton
              class="relative w-full lg:w-auto disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
            >
              <div class="flex-1 text-left font-light flex gap-1">
                <span class="text-text-secondary">Map:</span>
                <span v-if="selectedMap">{{ selectedMap.friendly_name }}</span>
                <span v-else>All</span>
              </div>
              <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1 flex flex-col px-4">
                  <MenuItem @click="(e) => { e.preventDefault(); updateSelectedMap(''); }">
                    <a
                      href="#"
                      :class="[
                        selectedMap === '' ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start font-light',
                      ]"
                    >
                      All Maps
                      <CheckIcon v-if="selectedMap === ''" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                  <MenuItem
                    :key="map"
                    v-for="map in cs_maps"
                    v-slot="{ active }"
                    @click="(e) => { e.preventDefault(); updateSelectedMap(map); }"
                  >
                    <a
                      href="#"
                      :class="[
                        active ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start',
                      ]"
                    >
                      {{ map.friendly_name }}
                      <CheckIcon v-if="map.name === selectedMap" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>

          <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
            <MenuButton
              class="relative w-full lg:w-auto disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
            >
              <div class="flex-1 text-left font-light flex gap-1">
                <span class="text-text-secondary">Platform:</span>
                <span v-if="selectedPlatform">{{ selectedPlatform }}</span>
                <span v-else>All</span>
              </div>
              <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1 flex flex-col px-4">
                  <MenuItem @click="(e) => { e.preventDefault(); updateSelectedPlatform(''); }">
                    <a
                      href="#"
                      :class="[
                        selectedPlatform === '' ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start font-light',
                      ]"
                    >
                      All Platforms
                      <CheckIcon v-if="selectedPlatform === ''" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                  <MenuItem @click="(e) => { e.preventDefault(); updateSelectedPlatform('Valve'); }">
                    <a
                      href="#"
                      :class="[
                        selectedPlatform === 'Valve' ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start font-light',
                      ]"
                    >
                      Valve
                      <CheckIcon v-if="selectedPlatform === 'Valve'" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                  <MenuItem @click="(e) => { e.preventDefault(); updateSelectedPlatform('Faceit'); }">
                    <a
                      href="#"
                      :class="[
                        selectedPlatform === 'Faceit' ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start font-light',
                      ]"
                    >
                      Faceit
                      <CheckIcon v-if="selectedPlatform === 'Faceit'" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>

          <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
            <MenuButton
              class="relative w-full lg:w-auto disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
            >
              <div class="flex-1 text-left font-light flex gap-1">
                <span class="text-text-secondary">Match Type:</span>
                <span v-if="selectedMatchType">{{ selectedMatchType }}</span>
                <span v-else>All</span>
              </div>
              <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1 flex flex-col px-4">
                  <MenuItem @click="(e) => { e.preventDefault(); updateSelectedMatchType(''); }">
                    <a
                      href="#"
                      :class="[
                        selectedMatchType === '' ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start font-light',
                      ]"
                    >
                      All Match Types
                      <CheckIcon v-if="selectedMatchType === ''" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                  <MenuItem
                    :key="type"
                    v-for="type in match_types"
                    v-slot="{ active }"
                    @click="(e) => { e.preventDefault(); updateSelectedMatchType(type); }"
                  >
                    <a
                      href="#"
                      :class="[
                        active ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start',
                      ]"
                    >
                      {{ type }}
                      <CheckIcon v-if="type === selectedMatchType" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>

        <div class="w-full flex flex-col gap-[2px] rounded-lg overflow-hidden">
          <div
            :class="[ userMatchHistory?.length > 0 ? 'px-4 py-2' : 'p-4']"
            class="bg-gray-900 text-text-primary text-xs tracking-[1.5px] flex-1 uppercase flex md:gap-2 items-center"
          >
            <div class="flex-1 md:flex-none">Last {{ userMatchHistory?.length }} Matches</div>
            <span v-if="userMatchHistory?.length > 0" class="hidden md:block text-text-secondary">•</span>
            <span
              v-if="userMatchHistory?.length > 0"
              class="hidden md:block"
              :class="{
                'text-success-400': userRecentStats?.win_rate > 50,
                'text-text-primary': userRecentStats?.win_rate == 50,
                'text-danger-400': userRecentStats?.win_rate < 50,
              }"
            >
              {{ userRecentStats?.win_rate }}%
            </span>
            <div class="flex-1 flex items-center" v-if="userMatchHistory?.length > 0">
              <h1 class="flex-1 hidden md:block">Win Ratio</h1>
              <div class="flex items-center justify-end gap-2 mr-2">
                <button
                  class="p-2 text-text-secondary hover:text-text-primary disabled:opacity-50 disabled:hover:text-text-secondary"
                  :disabled="currentPage === 1"
                  @click="changePage(currentPage - 1)"
                >
                <div class="flex bg-gray-700 p-2 rounded-lg">
                  <ArrowLeftIcon class="h-4 w-4 text-text-secondary"></ArrowLeftIcon>
                </div>

                </button>

                <button
                  v-for="page in displayedPages"
                  :key="page"
                  @click="changePage(page)"
                  class="px-3 py-1 rounded-md text-sm"
                  :class="[
                    currentPage === page
                      ? 'text-primary-500'
                      : 'text-text-secondary hover:text-text-primary'
                  ]"
                >
                  {{ page }}
                </button>

                <span v-if="hasEllipsis" class="text-text-secondary">...</span>

                <button
                  v-if="showLastPage && !displayedPages.includes(totalPageCount)"
                  @click="changePage(totalPageCount)"
                  class="px-3 py-1 rounded-md text-sm text-text-secondary hover:text-text-primary"
                  :class="{ 'bg-primary-600 text-text-primary': currentPage === totalPageCount }"
                >
                  {{ totalPageCount }}
                </button>

                <button
                  class="p-2 text-text-secondary hover:text-text-primary disabled:opacity-50 disabled:hover:text-text-secondary"
                  :disabled="currentPage === totalPageCount"
                  @click="changePage(currentPage + 1)"
                >
                  <div class="flex bg-gray-700 p-2 rounded-lg">
                    <ArrowRightIcon class="h-4 w-4 text-text-secondary"></ArrowRightIcon>
                  </div>
                </button>
              </div>
            </div>

            <div v-else class="flex-1 hidden md:block"></div>
          </div>

          <div v-if="userMatchHistory?.length > 0" v-for="match in userMatchHistory" class="bg-gray-900">
            <MatchRow :match="match" />
          </div>

          <div
            v-else
            class="bg-gray-900 p-4 text-text-secondary text-xs tracking-[1.5px]"
          >
            No Matches Found, make sure to add your Faceit ID and Steam tokens to your
            account in
            <router-link to="/settings" class="underline">settings!</router-link>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
  <StartServerModal
    :server="serverInfo"
    :show="showServerModal"
    :showMapSelector="showMapSelector"
    @close="showServerModal = false"
  />
</template>

<script setup>
import Dashboard from "../components/layouts/DashboardLayout.vue";
import Routine from "../components/Coach/Routine.vue";
import StatBlock from "../components/Matches/StatBlock.vue";
import MatchRow from "../components/UserProfile/MatchRow.vue";
import StartServerModal from "../components/StartServerModal.vue";
import axios from "axios";
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/vue/24/outline";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { ChevronDownIcon, CheckIcon } from "@heroicons/vue/24/outline";
import {
  formatStatString,
  toTitleCase,
  formatDataString,
  formatData,
  formatStatStringData,
} from "../utils/formatters";
import { Chart, registerables } from "chart.js";
import { LineChart } from "vue-chart-3";
import { createClickablePointsPlugin } from "../utils/chartPlugins.js";
import WeaknessessSection from '../components/Coach/WeaknessessSection.vue';
import StatComparisonBlock from '../components/Coach/StatComparisonBlock.vue';
import { useRouter } from "vue-router";

// Create the plugin
const clickablePointsPlugin = createClickablePointsPlugin(
  (index) => userMatchHistory.value[userMatchHistory.value.length - 1 - index]?.match_id,
  (matchId) => router.push(`/matches/${matchId}`)
);

Chart.register(...registerables, clickablePointsPlugin);

const router = useRouter();
const store = useStore();
const selectedMatchType = ref("");
const selectedPlatform = ref("");
const selectedMap = ref(null);
const dailyRoutines = ref([]);
const userMatchHistory = ref(null);
const playerStats = ref(null);
const selectedWeaknessStat = ref(null);
const userRecentStats = ref(null);
const totalMatchesCount = ref(0);
const totalPageCount = ref(0);
const maxVisiblePages = ref(5);
const currentPage = ref(1);
const loadingMatches = ref(false);
const auth = useAuth();
const user = ref(auth.user());
const extraUserStats = ref(null);
const mapStats = ref(null);
const competitiveMaps = ref(null);
const unsortedProgress = ref(null);
const activeServer = computed(() => store.getters.activeServer);
const launchLoading = ref(false);
const launchSuccess = ref(false);
const showServerModal = ref(false);
const serverInfo = ref(null);
const error = ref(null);
const userSettings = ref(null);
const showMapSelector = ref(false);

function getDailyRoutines() {
  axios({
    url: `/task_lists/my_daily_routine`,
    method: "GET",
  })
    .then((res) => {
      dailyRoutines.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

function getUserSettings() {
  axios.get(`/users/my_settings`).then((response) => {
    userSettings.value = response.data;
  }).catch((err) => {
    error.value = err;
  });
}

getUserSettings();

function getPlayerMatchHistory() {
  loadingMatches.value = true;

  axios.get(`/matches/recent_match_stats`, {
    params: {
      'steam_id': user.value?.steam_id,
      'match_type': selectedMatchType.value.toLowerCase(),
      'platform': selectedPlatform.value.toLowerCase(),
      'map_name': selectedMap.value?.name?.toLowerCase() || '',
      'page': currentPage.value
    },
  }).then((response) => {
    userMatchHistory.value = response.data.matches;
    totalMatchesCount.value = response.data.total_count;
    totalPageCount.value = response.data.page_count;
    loadingMatches.value = false;
    calculateRecentStats();
  }).catch((error) => {
    userMatchHistory.value = [];
    loadingMatches.value = false;
  });
}

function getPlayerStats() {
  axios.get(`/matches/user_profile`, {
    params: {
      'steam_id': user.value?.steam_id,
      'platform': selectedPlatform.value.toLowerCase(),
      'match_type': selectedMatchType.value.toLowerCase(),
    },
  }).then((response) => {
    playerStats.value = response.data.stats;
    extraUserStats.value = response.data.extra;
  }).catch((err) => {
    error.value = err;
  });
}

function getPlayerRecentProgress() {
  axios.get(`/matches/recent_player_progress`, {
    params: {
      'steam_id': user.value?.steam_id,
      'platform': selectedPlatform.value.toLowerCase(),
      'match_type': selectedMatchType.value.toLowerCase(),
    },
  }).then((response) => {
    unsortedProgress.value = response.data.stats;
  });
}

getPlayerRecentProgress();

function getMapStats() {
  axios.get(`/matches/player_map_stats`, {
    params: {
      'steam_id': user.value?.steam_id,
      'platform': selectedPlatform.value.toLowerCase(),
      'match_type': selectedMatchType.value.toLowerCase(),
    },
  }).then((response) => {
    mapStats.value = response.data;
  });
}

function changePage(page) {
  currentPage.value = page;
  getPlayerMatchHistory();
}

function calculateKD(kills, deaths) {
  return (kills / deaths).toFixed(2);
}

function getPerformance(map) {
  const hltvRating = map.stats.hltv_rating.players_value;

  if (hltvRating < 0.8) {
    return 'Poor';
  } else if (hltvRating >= 0.8 && hltvRating < 0.95) {
    return 'Average';
  } else if (hltvRating >= 0.95 && hltvRating < 1.05) {
    return 'Average';
  } else if (hltvRating >= 1.05 && hltvRating <= 1.15) {
    return 'Good';
  } else {
    return 'Very Good';
  }
}

getMapStats();
getPlayerStats();

function updateSelectedMap(value) {
  selectedMap.value = value;
  currentPage.value = 1;
  getPlayerMatchHistory();
}

function updateSelectedPlatform(value) {
  if (selectedPlatform.value !== value) {
    selectedMatchType.value = '';
  }
  selectedPlatform.value = value;
  currentPage.value = 1;
  getPlayerMatchHistory();
}

function updateSelectedMatchType(value) {
  selectedMatchType.value = value;
  currentPage.value = 1;
  getPlayerMatchHistory();
}

function updateSelectedWeaknessStat(stat) {
  selectedWeaknessStat.value = stat;
}

function calculateRecentStats() {
  const totalMatches = userMatchHistory.value?.length;
  if (totalMatches === 0) return;

  const wins = userMatchHistory?.value?.filter(match => match.match_result === 1)?.length;
  const losses = userMatchHistory?.value?.filter(match => match.match_result === 0)?.length;
  const ties = userMatchHistory?.value?.filter(match => match.match_result === 2)?.length;

  userRecentStats.value = {
    win_rate: ((wins / totalMatches) * 100).toFixed(0),
    loss_rate: ((losses / totalMatches) * 100).toFixed(0),
    tie_rate: ((ties / totalMatches) * 100).toFixed(0),
  };
}

function getBootcamp(mapName) {
  return mapName.replace('de_', '');
}

function launchMod(mod) {
  showMapSelector.value = true;
  serverInfo.value = {
    mode: mod,
  };
  showServerModal.value = true;
}

function launchRoutine(routine) {
  if (!activeServer.value?.id) {
    serverInfo.value = {
      mode: "Creator Studio",
      content: {
        task_list_id: routine.id,
        is_exam: false,
        title: routine.name,
        type: "task",
      },
    };
    showServerModal.value = true;
    return;
  }

  if (activeServer.value.status !== "online") {
    store.commit("showMessage", {
      messageType: "error",
      messageText: "Please wait for the server to come online before launching a routine",
    });
    return;
  }


  launchLoading.value = true;
  axios({
    url: `/cs_servers/${activeServer.value?.id}/exec_server_command`,
    method: "POST",
    timeout: 5000,
    data: {
      command: "launch_routine",
      value: `${routine.id}`,
      is_client_command: false,
    },
    headers: {
      "X-TEAM-ID": store.state.activeTeam.id,
    },
  })
    .then(() => {
      store.commit("showMessage", {
        messageType: "success",
        messageText: "Routine launched!",
      });
      launchLoading.value = false;
      launchSuccess.value = true;
      setTimeout(() => {
        launchSuccess.value = false;
        store.commit("hideMessage");
      }, 2000);
    })
    .catch((err) => {
      launchLoading.value = false;
      store.commit("showMessage", {
        messageType: "error",
        messageText: `Could not execute server command!`,
      });
    });
}

const bestMap = computed(() => {
  return mapStats?.value?.reduce((highest, current) => {
    if (!highest || current.win_rate > highest.win_rate) {
      return current;
    }
    return highest;
  }, null);
});

const worstMap = computed(() => {
  return mapStats?.value?.reduce((lowest, current) => {
    if (!lowest || current.win_rate < lowest.win_rate) {
      return current;
    }
    return lowest;
  }, null);
});

const displayedPages = computed(() => {
  if (totalPageCount.value <= maxVisiblePages.value) {
    return Array.from({ length: totalPageCount.value }, (_, i) => i + 1);
  }

  let start = Math.max(1, currentPage.value - Math.floor(maxVisiblePages.value / 2));
  let end = Math.min(start + maxVisiblePages.value - 1, totalPageCount.value);

  if (end - start + 1 < maxVisiblePages.value) {
    start = Math.max(1, end - maxVisiblePages.value + 1);
  }

  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
});

const hasEllipsis = computed(() => {
  return totalPageCount.value > maxVisiblePages.value &&
         !displayedPages.value.includes(totalPageCount.value);
});

const showLastPage = computed(() => {
  return totalPageCount.value > maxVisiblePages.value;
});

const showMatches = computed(() => {
  let show_matches = store.state.siteSettings?.find(
    (setting) => setting.name === "show_matches"
  )?.enabled;

  return show_matches;
});

const options = computed(() => ({
  spanGaps: true,
  plugins: {
    legend: {
      display: false, // Hide the legend
    },
    title: {
      display: false, // Hide the title
    },
    clickablePoints: clickablePointsPlugin,
  },
  elements: {
    point: {
      radius: 4,
      hoverRadius: 6,
    },
  },
  scales: {
    x: {
      display: false, // Hide the X-axis labels and grid lines
      grid: {
        color: "transparent",
        borderColor: "transparent",
      },
    },
    y: {
      grid: {
        borderDash: [5, 5],
        color: "#37485D",
        borderColor: "transparent",
      },
      ticks: {
        color: "#9CADC4",
        maxTicksLimit: 5,
      },
      beginAtZero: true, // Start the scale at 0
      suggestedMin: 0, // Ensure the minimum value is always 0 or lower
    },
  },
  maintainAspectRatio: false, // Allow the chart to adjust its size
  responsive: true,
}));

// Update the getChartData function
function getChartData() {
  if (userMatchHistory.value?.length === 0 || !selectedWeaknessStat.value) {
    return null;
  }

  // Create reversed arrays for labels and data
  const reversedLabels = [...userMatchHistory.value]
    .reverse()
    .map((match) => match.date.split("T")[0]);
  const reversedData = [...userMatchHistory.value]
    .reverse()
    .map((match) =>
      formatData(match[selectedWeaknessStat.value.title], selectedWeaknessStat.value.unit_type)
    );
  // Calculate the maximum value from both datasets
  const maxValue = Math.max(...reversedData, selectedWeaknessStat.value.rating_range_average_value);

  // Calculate a buffer (20% higher than the max value)
  const buffer = maxValue * 0.2;

  return {
    labels: reversedLabels,
    datasets: [
      {
        data: reversedData,
        fill: true,
        backgroundColor: "#6B70D115",
        borderColor: "#6B70D1",
        tension: 0,
        pointRadius: 3,
        pointHoverBackgroundColor: "#6B70D1",
        pointHoverBorderColor: "#FFFFFF",
      },
      {
        data: Array(userMatchHistory.value.length).fill(formatData(selectedWeaknessStat.value.rating_range_average_value, selectedWeaknessStat.value.unit_type)),
        fill: false,
        borderColor: "#C44CB6",
        borderDash: [5, 5],
        pointRadius: 0,
        borderWidth: 1,
      },
    ],
  };
}

const orderedProgress = computed(() => {
  if (!unsortedProgress.value) {
    return [];
  }

  let improvements = [];

  // Iterate over the keys in playerStats
  for (const key in unsortedProgress.value) {
    const stat = unsortedProgress.value[key];

    // Ensure the stat has both recent_value and previous_value
    if (stat.recent_value !== undefined && stat.previous_value !== undefined) {
      let improvement;
      let percentImprovement;

      // Calculate the improvement based on the is_ascending flag
      if (stat.is_ascending) {
        improvement = stat.recent_value - stat.previous_value;
      } else {
        improvement = stat.previous_value - stat.recent_value;
      }

      // Calculate the percentage improvement
      let previousValue = stat.previous_value === 0 ? 1 : stat.previous_value;
      percentImprovement = (improvement / previousValue) * 100;

      improvements.push({
        title: key,
        value: stat.recent_value,
        improvement: improvement.toFixed(2),
        percentImprovement: percentImprovement.toFixed(2),
        unit_type: stat.unit_type,
        is_ascending: stat.is_ascending,
      });
    }
  }

  // Sort the improvements to get the most improved stats first
  improvements.sort((a, b) => b.percentImprovement - a.percentImprovement);

  return improvements;
});

const orderedStats = computed(() => {
  if (!playerStats.value) {
    return [];
  }

  let differences = [];

  // Iterate over the keys in playerStats
  for (const key in playerStats.value) {
    const stat = playerStats.value[key];

    if (!stat.recommended_mods?.length) {
      continue;
    }

    // Calculate the difference between the average and player's value
    let difference;
    let percentDifference;

    // Adjust the difference calculation based on the is_ascending flag
    if (stat.is_ascending) {
      difference = stat.players_value - stat.rating_range_average_value;
    } else {
      difference = stat.rating_range_average_value - stat.players_value;
    }

    // Calculate the percentage difference
    let averageValue = stat.rating_range_average_value === 0 ? 1 : stat.rating_range_average_value;
    percentDifference = (difference / averageValue) * 100;

    // Temporarily set the counter strafing stat name
    let friendly_title = key;
    if(key === "shots_at_low_velocity_rate") {
      friendly_title = "counter_strafing";
    }

    differences.push({
      title: key,
      friendly_title,
      value: stat.players_value,
      difference: difference.toFixed(2),
      percentDifference: percentDifference.toFixed(2),
      unit_type: stat.unit_type,
      is_ascending: stat.is_ascending,
      recommended_mods: stat.recommended_mods,
      rating_range_average_value: stat.rating_range_average_value,
    });
  }

  // Sort the differences
  differences.sort((a, b) => a.percentDifference - b.percentDifference);
  
  // Update selected stat if there are negative differences
  if(differences[0]?.percentDifference < 0) {
    updateSelectedWeaknessStat(differences[0]);
  }
  
  return differences;
});

const lowestStats = computed(() => {
  return orderedStats.value.filter((stat) => stat.percentDifference < 0);
});

const positiveStats = computed(() => {
  return orderedStats.value
    .filter((stat) => stat.percentDifference > 0)
    ?.sort((a, b) => b.percentDifference - a.percentDifference);
});

const match_types = computed(() => {
  if (selectedPlatform.value === 'Valve') {
    return [
      "Competitive",
      "Premier",
      "Wingman"
    ];
  }

  if (selectedPlatform.value === 'Faceit') {
    return [
      "Matchmaking",
      "Championship",
    ]
  }
});

watch(lowestStats, (newStats) => {
  if (newStats.length > 0) {
    selectedWeaknessStat.value = newStats[0];
  }
}, { immediate: true });

watch(() => store.getters.modInfo, (newModInfo) => {
  if(newModInfo?.mods) {
    competitiveMaps.value = newModInfo.mods.find(mod => mod.name === "competitive")?.cs_maps;
  }
}, { immediate: true });

getDailyRoutines();
getPlayerMatchHistory();

const statlessMaps = computed(() => {
  if(!mapStats?.value) {
    return [];
  }
  // Get the list of map names from mapStats
  const mapStatsNames = new Set(mapStats?.value?.map(map => map.map_name));
  
  // Filter maps from mapInfo that are not in mapStats
  return competitiveMaps?.value?.filter(map => !mapStatsNames.has(map.name))?.map(map => ({
    name: map.name,
    friendly_name: map.friendly_name
  }));
});

</script>


<style scoped>

.green-gradient {
  background: radial-gradient(51.81% 50% at 51.81% 0%, rgba(37, 229, 206, 0.20) 0%, rgba(37, 229, 206, 0.00) 100%), var(--Gray-900, #0F141A);
}

.image-gradient {
  background: linear-gradient(180deg, rgba(15, 19, 26, 0.90) 0%, #0F131A 50%, #0F131A 100%), url(<path-to-image>) lightgray 50% / cover no-repeat;
}

</style>
